const API = 'UserPatientProfileSessions';

export const API_USER_PATIENT_PROFILE_SESSIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserPatientProfileSession, 'id'>) =>
    `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserPatientProfileSession, 'id'>) =>
    `${API}/Delete/${data.id}`,
};

export interface GetAllSurveyAnswersItem
  extends Pick<Components.Schemas.UserPatientProfileSession, 'id' | 'notebookID' | 'entryDate'> {
  notebook: Pick<Required<Components.Schemas.UserPatientProfileSession>['notebook'], 'labelKey'>;
}
