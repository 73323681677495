import { DynamicService } from 'modules/dynamic-service';
import { dynamicNamespace } from 'utils/dynamic';
import { apiRtk, RTK_TAGS, transformResponseDynamic } from 'utils/service';
import { API_USER_PATIENT_PROFILE_SESSIONS, GetAllSurveyAnswersItem } from './models';
const dynamic = dynamicNamespace<Components.Schemas.UserPatientProfileSession>();

class Service extends DynamicService<Components.Schemas.UserPatientProfileSession> {}

export const ServiceUserPatientProfileSessions = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSIONS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSIONS.POST,
  patch: API_USER_PATIENT_PROFILE_SESSIONS.PATCH,
  delete: API_USER_PATIENT_PROFILE_SESSIONS.DELETE,
});

export const apiUserPatientProfileSessions = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getAllSurveyAnswers: build.query<GetAllSurveyAnswersItem[], void>({
        query: () => ({
          url: API_USER_PATIENT_PROFILE_SESSIONS.GET_ALL_DYNAMIC,
          params: {
            select: dynamic.select(
              'id',
              'notebookID',
              'entryDate',
              'new { notebook.labelKey } as notebook',
            ),
            orderBy: 'entryDate desc',
          },
        }),
        transformResponse: transformResponseDynamic,
        providesTags: [{ type: RTK_TAGS.SESSIONS }],
      }),
    };
  },
});

export * from './models';
