import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import he from 'date-fns/locale/he';
import React, { memo, useMemo } from 'react';

interface ContextValue {
  locale: typeof he;
}
export const DatePickerProviderContext = React.createContext<ContextValue>({
  locale: he,
});

interface Props {
  children: React.ReactNode;
}
export const AppDatePickerProvider = memo<Props>(({ children }) => {
  const locale = he;

  const value = useMemo(() => {
    return { locale };
  }, [locale]);

  return (
    <DatePickerProviderContext.Provider value={value}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
    </DatePickerProviderContext.Provider>
  );
});
