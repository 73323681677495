import { Paper } from '@mui/material';
import { PrivateNavigation } from 'layouts/private/components/navigation';
import React from 'react';
import style from './index.module.scss';

interface Props {}

export const PrivateBottomNavigation: React.FC<Props> = () => {
  return (
    <Paper className={style.root}>
      <PrivateNavigation />
    </Paper>
  );
};
