import CssBaseline from '@mui/material/CssBaseline';
import { ErrorBoundary } from 'components/error-boundary';
import {
  AppInitLoader,
  AppNotificationsProvider,
  AppPageLayout,
  AppThemeProvider,
  AppWebNotifications,
  AxiosInterceptorsProvider,
  InstallDesktopAppProvider,
  MetaProvider,
  TranslateProvider,
} from 'contexts';
import { AuthProvider } from 'contexts/auth';
import { AppDatePickerProvider } from 'contexts/date-picker-provider';
import { AppPrivateDataLoader } from 'contexts/private-data-loader';
import React from 'react';
// store
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRoutes } from 'router';
import 'slick-carousel/slick/slick-theme.css';
// Import css files
import 'slick-carousel/slick/slick.css';
import { appPersistor, appStore } from 'store';
import 'styles/app.global.scss';

function App() {
  return (
    <StoreProvider store={appStore}>
      <PersistGate persistor={appPersistor} loading={null}>
        <ErrorBoundary>
          <AppDatePickerProvider>
            <InstallDesktopAppProvider>
              <AxiosInterceptorsProvider>
                <AppNotificationsProvider>
                  <MetaProvider>
                    <AuthProvider>
                      <AppPageLayout>
                        <AppThemeProvider>
                          <TranslateProvider>
                            <AppWebNotifications>
                              <CssBaseline />
                              <AppInitLoader>
                                <AppPrivateDataLoader>
                                  <AppRoutes />
                                </AppPrivateDataLoader>
                              </AppInitLoader>
                            </AppWebNotifications>
                          </TranslateProvider>
                        </AppThemeProvider>
                      </AppPageLayout>
                    </AuthProvider>
                  </MetaProvider>
                </AppNotificationsProvider>
              </AxiosInterceptorsProvider>
            </InstallDesktopAppProvider>
          </AppDatePickerProvider>
        </ErrorBoundary>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
