import Cookies from 'js-cookie';
import { convertToDate } from './dates';
const TOKEN_PREFIX = 'raz-clinic-patient';

const APP_TOKEN = `${TOKEN_PREFIX}_token`;
const APP_REFRESH = `${TOKEN_PREFIX}_token_refresh`;
const APP_EXPIRE = `${TOKEN_PREFIX}_token_date`;

interface TokenOptions {
  token: string;
  refreshToken: string;
  expires: string;
}

export const updateAuthTokens = ({ token, expires, refreshToken }: Partial<TokenOptions> = {}) => {
  token
    ? Cookies.set(APP_TOKEN, token, { expires: convertToDate(expires) })
    : Cookies.remove(APP_TOKEN);

  refreshToken
    ? Cookies.set(APP_REFRESH, refreshToken, { expires: convertToDate(expires) })
    : Cookies.remove(APP_REFRESH);

  expires
    ? Cookies.set(APP_EXPIRE, expires, { expires: convertToDate(expires) })
    : Cookies.remove(APP_EXPIRE);
};
export const getAuthTokens = () => {
  return {
    token: Cookies.get(APP_TOKEN),
    refreshToken: Cookies.get(APP_REFRESH),
    expires: Cookies.get(APP_EXPIRE),
  };
};

export const parseJwt = <R>(token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1])) as R;
  } catch (e) {
    return null;
  }
};
