const API = 'Subscriptions';

export const API_SUBSCRIPTION = {
  GET_SUBSCRIPTIONS: `${API}/GetSubscriptionsDynamic`,
};
export interface Subscription {
  id: string;
  isActive: boolean;
  labelKey: string;
  externalSystemTitle: string;
  listPrice: number;
  durationMonths: number;
  visitDietitian: boolean;
  visitDoctor: boolean;
}
export interface PatientSubscription {
  id: string;
  isActive: boolean;
  userPatientProfileID: string;
  subscriptionID: string;
  price: number;
  paymentDate: string;
  startDate: string;
  endDate: string;
  remark: string;
  subscription: Subscription;
  invoiceDocUrl: string;
}

export interface DashboardPatientSubscription
  extends Pick<PatientSubscription, 'id' | 'price' | 'remark' | 'startDate' | 'endDate'> {
  subscriptionTitle: Subscription['labelKey'];
  subscriptionListPrice: Subscription['listPrice'];
  subscriptionDurationMonths: Subscription['durationMonths'];
}
export interface CurrentSubscription
  extends Pick<
    PatientSubscription,
    'id' | 'startDate' | 'endDate' | 'remark' | 'invoiceDocUrl' | 'price'
  > {
  subscriptionTitle: Subscription['labelKey'];
  listPrice: Subscription['listPrice'];
}

export interface PreviousSubscription extends CurrentSubscription {}
