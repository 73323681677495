import { DynamicService } from 'modules/dynamic-service';
import * as dynamic from 'utils/dynamic';
import { apiRtk } from 'utils/service';
import {
  API_TODO_TASK_CATEGORIES,
  TaskTag,
  ToDoTaskCategory,
  TodoTaskCategoryForTaskData,
} from './models';
class Service extends DynamicService<ToDoTaskCategory> {}

export const ServiceTodoTaskCategories = new Service({
  mainField: 'id',
  getAll: API_TODO_TASK_CATEGORIES.GET_ALL_DYNAMIC,
  post: API_TODO_TASK_CATEGORIES.POST,
  patch: API_TODO_TASK_CATEGORIES.PATCH,
  delete: API_TODO_TASK_CATEGORIES.DELETE,
});

export const apiTodoTaskCategories = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getTodoTaskCategoryForTaskDataByTag: builder.query<TodoTaskCategoryForTaskData, TaskTag>({
      queryFn: async (tag) => {
        try {
          const response =
            await ServiceTodoTaskCategories.getAllDynamic<TodoTaskCategoryForTaskData>({
              select: dynamic.select(
                'id',
                'title',
                'defaultMessage',
                'userEmployeeProfileToDoTaskCategories.Where(ec => ec.userEmployeeProfile.isActive).Select(ec => ec.userEmployeeProfileID) as userEmployeeProfileIDs',
              ),
              filter: dynamic
                .mergeFilters(dynamic.makeFilter('taskTag', tag, dynamic.equals))
                .join('&&'),
              take: 1,
            });

          const res = Service.normalizeResponseSingle(response);

          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

export * from './models';
