import { ActionFromMatcher } from '@reduxjs/toolkit/dist/tsHelpers';
import i18n from 'modules/i18n';
import { Label } from 'services/labels';
import { select, takeLatest } from 'typed-redux-saga';
import { actionLabelsInit } from './actions';
import { selectLanguageID } from './selectors';

const reducerMakeTranslation = (acc: Record<string, string>, item: Label) => {
  const ket = String(item.labelKey);
  acc[ket] = String(item.title);
  return acc;
};

function* sagaWatchLabels(action: ActionFromMatcher<typeof actionLabelsInit.fulfilled>) {
  const {
    payload: { languageID, data },
  } = action;

  i18n.addResources(
    languageID,
    'translation',
    data.reduce(reducerMakeTranslation, {} as Record<string, string>),
  );

  const currentLanguageID = yield* select(selectLanguageID);

  if (currentLanguageID === languageID) {
    i18n.changeLanguage(languageID);
  }
}

export const sagasLabels = [takeLatest(actionLabelsInit.fulfilled, sagaWatchLabels)];
