import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import { calcTranslate } from 'utils/other';
import { apiAppProxy, apiRtk, DynamicResult, transformResponseDynamic } from 'utils/service';
import {
  API_NOTEBOOKS,
  MAP_SURVEY_OPTIONS,
  SurveyListAlonItem,
  SurveyListComplexItem,
  SurveyListItem,
  SurveyQuestion,
} from './models';
const dynamic = dynamicNamespace<Components.Schemas.Notebook>();

export * from './models';

class Service {
  async getSurvey(id: string) {
    const result = await apiAppProxy.get<DynamicResult<SurveyQuestion>>(
      API_NOTEBOOKS.GET_ALL_DYNAMIC,
      {
        params: {
          select: dynamic.select(
            'id',
            'labelKey',
            'rowIndex',
            `notebookFieldInputs.OrderBy(i => i.rank).Where(k => k.isActive==true)
            .Select(k => new {
               k.id,
               k.isRequired,
               k.rank,
               k.defaultValue,
               k.fieldInputID,
               k.fieldInput.labelKey ,
               k.fieldInput.inputType.inputTypeDataSource as dataSource,
               k.fieldInput.inputType.inputTypeParameter as parameter
            }) as notebookFieldInputs`,
          ),
          filter: mergeFilters(
            dynamic.makeFilter('id', id, equals),
            dynamic.makeFilter('isActive', true, equals),
            dynamic.makeFilter('isForPatient', true, equals),
          ).join('&&'),
          take: 1,
        },
      },
    );

    const {
      data: {
        value: [data],
      },
    } = result;

    if (!data) {
      throw new Error('survey-was-not-found');
    }

    return { ...result, data };
  }
  getDynamicSourceArgs(dataSource: Components.Schemas.InputTypeDataSource) {
    const { fieldVal, fieldKey, orderBy, dataSourceName, filter } = dataSource;
    const labelFields = ((fieldVal || '').match(/({.*?})/gm) || []).map((val: string) =>
      val.slice(1, -1),
    );
    return {
      url: dataSourceName || '',
      filter: filter ?? undefined,
      select: [fieldKey, ...labelFields].filter(Boolean).join(','),
      orderBy: orderBy || undefined,
    };
  }
  makeDynamicSourceOption(
    dataSource: Pick<Components.Schemas.InputTypeDataSource, 'fieldKey' | 'fieldVal'>,
  ) {
    const { fieldKey, fieldVal } = dataSource;
    return (item: any) => ({
      id: fieldKey ? item[fieldKey] : item.id,
      label: calcTranslate(
        String(fieldVal || '')
          .replace(/{/gm, '{{')
          .replace(/}/gm, '}}'),
        item,
      ),
    });
  }
}

export const ServiceNotebooks = new Service();

export const apiNotebooks = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getAllSurveys: build.query<SurveyListItem[], void>({
        query: () => ({
          url: API_NOTEBOOKS.GET_ALL_DYNAMIC,
          params: {
            select: dynamic.select('id', 'labelKey as title', 'rowIndex'),
          },
        }),
        transformResponse: (result: DynamicResult<SurveyListAlonItem>) => {
          const items = transformResponseDynamic(result);

          let stayAloneItems: SurveyListAlonItem[] = [...items];
          let complexItems: SurveyListComplexItem[] = [];

          MAP_SURVEY_OPTIONS.forEach((config, mapIndex) => {
            config.options.forEach((mapRowIndex) => {
              const findIndex = stayAloneItems.findIndex(
                ({ rowIndex }) => rowIndex === mapRowIndex,
              );

              if (findIndex > -1) {
                const [option] = stayAloneItems.splice(findIndex, 1);
                let complexItem = complexItems[mapIndex];
                if (!complexItem) {
                  complexItems[mapIndex] = {
                    id: String(option.id),
                    type: config.type,
                    rowIndexes: [Number(option.rowIndex)],
                    labelKey: config.labelKey,
                    options: [option],
                  };
                } else {
                  complexItem.id = `${complexItem.id}__${option.id}`;
                  complexItem.rowIndexes.push();
                  complexItem.options.push(option);
                }
              }
            });
          });

          return [...complexItems, ...stayAloneItems];
        },
      }),
      getSurvey: build.query<SurveyQuestion, { id: string }>({
        queryFn: async ({ id }) => {
          try {
            const { data } = await ServiceNotebooks.getSurvey(id);

            return { data };
          } catch (e: any) {
            return { error: e, data: undefined };
          }
        },
      }),
      getDynamicSource: build.query<
        any[],
        { url: string; select?: string; orderBy?: string; filter?: string }
      >({
        query: ({ url, select, orderBy, filter }) => ({
          url,
          params: {
            select,
            filter,
            orderBy,
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
    };
  },
});
