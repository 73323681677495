import { Loading } from 'components/loading';
import { PageLoader } from 'components/page-loader';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountStatuses } from 'store/accounts';
import { selectLabelsStatuses } from 'store/labels';

export const AppInitLoader: React.FC = ({ children }) => {
  const { isInit: isInitAccount, isLoading: isLoadingAccount } = useSelector(selectAccountStatuses);
  const { isInit: isInitLabels, isLoading: isLoadingLabels } = useSelector(selectLabelsStatuses);

  const isInit = useMemo(() => {
    return [isInitAccount, isInitLabels].every(Boolean);
  }, [isInitAccount, isInitLabels]);

  return (
    <>
      {isInit && children}
      {isLoadingLabels && <PageLoader />}
      {isLoadingAccount && <Loading />}
    </>
  );
};
