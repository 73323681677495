import { ChangeLogConfig } from 'modules/change-log';
import { dateFormat, isDateLike } from 'utils/dates';
import { InferType } from 'yup';
import { schemaCurrentUserInfo } from './models';

type Config = ChangeLogConfig<Components.Schemas.CurrentUserProfileResponse>;

export const logConfig: Record<keyof InferType<typeof schemaCurrentUserInfo>, Config> = {
  firstName: {
    select: 'firstName',
    getValue: (res) => String(res.firstName),
  },
  lastName: {
    select: 'lastName',
    getValue: (res) => String(res.lastName),
  },
  idNumber: {
    select: 'idNumber',
    getValue: (res) => String(res.idNumber),
  },
  mobilePhone: {
    select: 'mobilePhone',
    getValue: (res) => String(res.mobilePhone),
  },
  occupation: {
    select: 'occupation',
    getValue: (res) => String(res.occupation),
  },
  email: {
    select: 'email',
    getValue: (res) => String(res.email),
  },
  genderID: {
    select: 'genderTitle',
    getValue: (res) => String(res.genderTitle),
  },
  cityID: {
    select: 'cityTitle',
    getValue: (res) => String(res.cityTitle),
  },
  weight: {
    select: 'weight',
    getValue: (res) => String(res.weight),
  },
  height: {
    select: 'height',
    getValue: (res) => String(res.height),
  },
  dateOfBirth: {
    select: 'dateOfBirth',
    getValue: (res) =>
      isDateLike(res.dateOfBirth) ? dateFormat(res.dateOfBirth) : String(res.dateOfBirth),
  },
  address: {
    select: 'address',
    getValue: (res) => String(res.address),
  },
};
