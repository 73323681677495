import { Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { ServiceErrorHelpers } from 'services/error-helpers';
import { AppState } from '../../store';
import style from './index.module.scss';

class ErrorBoundaryComponent extends React.Component<
  { user: AppState['accounts']['user'] },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (process.env.NODE_ENV !== 'development') {
      this.notifyError(error, errorInfo);
    }
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  notifyError(error: any, errorInfo: any) {
    const user = this.props.user;
    try {
      ServiceErrorHelpers.sendError({
        userPatientProfileID: user ? user.appUserID || '' : '',
        message: error?.message || 'unknown',
        stack: errorInfo?.componentStack || 'unknown',
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={style.wrap}>
          <Typography variant={'h1'}>Something went wrong ;(</Typography>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapState = (state: AppState) => {
  return { user: state.accounts.user };
};

export const ErrorBoundary = connect(mapState)(ErrorBoundaryComponent);
