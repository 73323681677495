import { DynamicService } from 'modules/dynamic-service';
import { dateEquals, dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import {
  base64ToFileStream,
  getFileInfo,
  getFileNameExt,
  isFileLike,
  ValueFileUploader,
} from 'utils/file-uploader';
import { apiRtk, RTK_TAGS } from 'utils/service';
import { apiMediaUploads, ServiceMediaPrivate } from '../media-uploads';
import { ServiceUserPatientProfileDietDiaryImages } from '../user-patient-profile-diet-diary-images';
import {
  API_USER_PATIENT_PROFILE_DIET_DIARIES,
  GetListUserPatientProfileDietDiariesArg,
  GetListUserPatientProfileDietDiariesItemOutput,
  UserPatientProfileDietDiaryDeleteInput,
  UserPatientProfileDietDiaryPostInput,
} from './models';

const dynamic = dynamicNamespace<Components.Schemas.UserPatientProfileDietDiary>();
class Service extends DynamicService<Components.Schemas.UserPatientProfileDietDiary> {
  private makeFileName = (input: {
    userPatientProfileID: string;
    fileName: string;
    index: number;
  }) => {
    const { userPatientProfileID, index, fileName } = input;
    const ext = getFileNameExt(fileName);
    return `${userPatientProfileID}/files/diet-diary__${index}__${new Date().toISOString()}.${ext}`;
  };
  private saveFileToCloud = async (input: {
    file: ValueFileUploader;
    userPatientProfileID: string;
    index: number;
  }) => {
    const { file, userPatientProfileID, index } = input;
    if (isFileLike(file)) {
      const { fileName } = getFileInfo(file);
      const __fileName = this.makeFileName({
        fileName,
        userPatientProfileID,
        index,
      });
      const { data } = await ServiceMediaPrivate.uploadFile({
        fileName: __fileName,
        fileStreamString: base64ToFileStream(file.value),
      });

      return data.filePath;
    }
    return file;
  };
  private saveFilesToCloud = async (input: {
    files: ValueFileUploader[];
    userPatientProfileID: string;
  }) => {
    const { files, userPatientProfileID } = input;
    return await Promise.all(
      files.map((file, index) => this.saveFileToCloud({ file, userPatientProfileID, index })),
    );
  };
  private saveImages = async (input: { filePaths: string[]; id: string }) => {
    return ServiceUserPatientProfileDietDiaryImages.createBulk(input);
  };
  postWithFiles = async (input: UserPatientProfileDietDiaryPostInput) => {
    const { fileURLs, ...rest } = input;

    const result = await this.post(rest);

    const id = String(result.data.id);

    const filePaths = await this.saveFilesToCloud({
      files: fileURLs,
      userPatientProfileID: String(input.userPatientProfileID),
    });

    await this.saveImages({ id, filePaths });

    return { ...result, data: { ...result.data } };
  };
}

export const ServiceUserPatientProfileDietDiaries = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_DIET_DIARIES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_DIET_DIARIES.POST,
  patch: API_USER_PATIENT_PROFILE_DIET_DIARIES.PATCH,
  delete: API_USER_PATIENT_PROFILE_DIET_DIARIES.DELETE,
});

export const apiUserPatientProfileDietDiaries = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getListUserPatientProfileDietDiaries: builder.query({
      queryFn: async (arg: GetListUserPatientProfileDietDiariesArg, { dispatch }) => {
        const { data } =
          await ServiceUserPatientProfileDietDiaries.getAllDynamic<GetListUserPatientProfileDietDiariesItemOutput>(
            {
              select: dynamic.select(
                'id',
                'dietDiaryTypeID',
                'entryDate',
                'description',
                'isViewed',
                'reply',
                'replyDate',
                'new { userEmployeeProfile.fullName, userEmployeeProfile.userPhoto } as userEmployeeProfile',
                'userPatientProfileDietDiaryImages.Select(i=> i.fileURL) as fileURLs',
              ),
              filter: mergeFilters(
                dynamic.makeFilter('userPatientProfileID', arg.userPatientProfileID, equals),
                dynamic.makeFilter('entryDate', arg.date, dateEquals),
              ).join('&&'),
              orderBy: dynamic.orderBy('entryDate', 'asc'),
            },
          );

        const transformed = await Promise.all(
          data.value.map(async (item) => {
            const files = await Promise.all(
              item.fileURLs.map(async (fileURL) => {
                const request = dispatch(apiMediaUploads.endpoints.getFile.initiate(fileURL));
                request.unsubscribe();

                const base64 = await request.unwrap();
                return { fileURL, base64 };
              }),
            );

            return { ...item, files };
          }),
        );

        return { data: transformed };
      },
      providesTags: [{ type: RTK_TAGS.DIET_DIARIES, id: 'list' }],
    }),
    createUserPatientProfileDietDiary: builder.mutation({
      queryFn: (data: UserPatientProfileDietDiaryPostInput) => {
        return ServiceUserPatientProfileDietDiaries.postWithFiles(data);
      },
      invalidatesTags: [{ type: RTK_TAGS.DIET_DIARIES, id: 'list' }],
    }),
    deleteUserPatientProfileDietDiary: builder.mutation({
      queryFn: (data: UserPatientProfileDietDiaryDeleteInput) => {
        return ServiceUserPatientProfileDietDiaries.delete(data);
      },
      invalidatesTags: [{ type: RTK_TAGS.DIET_DIARIES, id: 'list' }],
    }),
  }),
});

export * from './models';
