import { Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as LogoText } from 'images/logo-text.svg';
import { ReactComponent as LogoIcon } from 'images/logo.svg';
import React from 'react';
import style from './index.module.scss';

interface Classes {
  root: string;
  icon: string;
  iconText: string;
  text: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const Logo: React.FC<Props> = ({ className, classes, children }) => {
  return (
    <div className={clsx(style.root, classes?.root, className)}>
      <div className={clsx(style.icon, classes?.icon)}>
        <LogoIcon />
      </div>
      <div className={clsx(style.iconText, classes?.iconText)}>
        <LogoText />
      </div>
      <Typography
        noWrap
        className={clsx(style.text, classes?.text)}
        variant={'body2'}
        color={'primary'}
      >
        {children}
      </Typography>
    </div>
  );
};
