import { useAppDispatch } from 'hooks';
import React, { useEffect } from 'react';
import { actionLabelsInit } from 'store/labels';

export const TranslateProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actionLabelsInit());
  }, [dispatch]);

  return <>{children}</>;
};
