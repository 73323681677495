import { PopupNotification } from 'components/popup-notification';
import { useAppDispatch, useAppSelector, useOpen } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { ServiceWebPush } from 'services/web-push';
import {
  actionAccountNotificationsSet,
  selectAccountNotifications,
  selectAccountStatuses,
  selectAccountUser,
} from 'store/accounts';
import { urlBase64ToUint8Array } from 'utils/other';
import { useWebNotification, WebNotificationProvider } from './web-notification-provider';

const AskToSubscribe: React.FC = () => {
  const user = useAppSelector(selectAccountUser);
  const { isLoading } = useAppSelector(selectAccountStatuses);
  const { notificationsDoNotAskAgain } = useAppSelector(selectAccountNotifications);
  const dispatch = useAppDispatch();
  const { isSupport, isInit, permission, subscription, requestSubscribe } = useWebNotification();

  const shouldShow = Boolean(
    isSupport && isInit && permission === 'default' && user && !notificationsDoNotAskAgain,
  );
  const { isOpen: isOpenDialog, onClose: onCloseDialog } = useOpen(true);
  const isOpen = shouldShow && isOpenDialog;

  const onAccept = useCallback(async () => {
    onCloseDialog();
    await requestSubscribe();
  }, [requestSubscribe, onCloseDialog]);

  const onClose = useCallback(() => {
    onCloseDialog();
    dispatch(actionAccountNotificationsSet({ notificationsDoNotAskAgain: true }));
  }, [dispatch, onCloseDialog]);

  useEffect(() => {
    if (permission === 'granted' && isInit && !subscription && user && !isLoading) {
      requestSubscribe();
    }
  }, [permission, isInit, subscription, user, requestSubscribe, isLoading]);

  return <PopupNotification isOpen={isOpen} onClose={onClose} onAccept={onAccept} />;
};

interface Props {
  children: React.ReactNode;
}
export const AppWebNotifications: React.FC<Props> = ({ children }) => {
  const user = useAppSelector(selectAccountUser);
  const getSubscribeOptions = useCallback(async () => {
    const publicVapidKey = await ServiceWebPush.getPublicKey();
    return { applicationServerKey: urlBase64ToUint8Array(publicVapidKey), userVisibleOnly: true };
  }, []);

  const userPatientProfileID = user?.appUserID;
  const onSubscribed = useCallback(
    (subscription: PushSubscription) => {
      if (!userPatientProfileID) return;

      ServiceWebPush.subscribeUser({ userPatientProfileID, subscription });
    },
    [userPatientProfileID],
  );

  return (
    <WebNotificationProvider getSubscribeOptions={getSubscribeOptions} onSubscribed={onSubscribed}>
      {children}
      <AskToSubscribe />
    </WebNotificationProvider>
  );
};
