const API = 'Notebooks';

export const API_NOTEBOOKS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
};

export const NOTEBOOK_SURVEY_HEALTH_EN_ROW_INDEX = 235;
export const NOTEBOOK_SURVEY_HEALTH_HE_ROW_INDEX = 234;

export enum YesNoAnswerIDs {
  YES_HE = '13f356ee-2bd2-4e5a-9af8-c42c8d5096b5',
  YES_EN = 'aec0b301-992c-4fb5-a9e6-b3662876710c',
}

export enum SURVEY_TYPES {
  HEALTH_SURVEY = 'health-survey',
}
export const MAP_SURVEY_OPTIONS = [
  {
    type: SURVEY_TYPES.HEALTH_SURVEY,
    labelKey: 'health-survey',
    options: [NOTEBOOK_SURVEY_HEALTH_EN_ROW_INDEX, NOTEBOOK_SURVEY_HEALTH_HE_ROW_INDEX],
  },
];

interface INotebookFieldInput
  extends Pick<
    Components.Schemas.NotebookFieldInput,
    'id' | 'isRequired' | 'rank' | 'defaultValue' | 'fieldInputID'
  > {
  id: string;
  labelKey: Required<Components.Schemas.NotebookFieldInput>['fieldInput']['labelKey'];
  dataSource: Required<
    Required<Components.Schemas.NotebookFieldInput>['fieldInput']
  >['inputType']['inputTypeDataSource'];
  parameter: Required<
    Required<Components.Schemas.NotebookFieldInput>['fieldInput']
  >['inputType']['inputTypeParameter'];
}
export interface SurveyQuestion
  extends Pick<Components.Schemas.Notebook, 'id' | 'labelKey' | 'rowIndex'> {
  notebookFieldInputs: INotebookFieldInput[];
}

export interface SurveyListAlonItem extends Pick<Components.Schemas.Notebook, 'id' | 'rowIndex'> {
  title: string;
}
export interface SurveyListComplexItem {
  id: string;
  type: SURVEY_TYPES;
  labelKey: string;
  rowIndexes: number[];
  options: SurveyListAlonItem[];
}
export type SurveyListItem = SurveyListAlonItem | SurveyListComplexItem;
