import { useAppDispatch, useAppSelector } from 'hooks';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { actionAccountInstalledSet, selectAccountInstalled } from 'store/accounts';
import { DateValue } from 'utils/dates';

interface InstallDesktopAppContextValue {
  canInstall: boolean;
  isInstalled: boolean;
  lastAskDate: DateValue | null;
  doNotAskAgain: boolean;
  onAgree: (doNotAskAgain: boolean) => void;
  onDisagree: (doNotAskAgain: boolean) => void;
}
const InstallDesktopAppContext = createContext<InstallDesktopAppContextValue | null>(null);

export const useInstallDesktopApp = () => {
  const context = useContext(InstallDesktopAppContext);

  if (!context) {
    throw new Error('InstallDesktopApp was not provided');
  }

  return context;
};

export const InstallDesktopAppProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const [event, setEvent] = useState<any>();
  const { isInstalled, installLastDate, installDoNotAskAgain } =
    useAppSelector(selectAccountInstalled);
  const [canInstall, setCanInstall] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setEvent(e);
      // Update UI notify the user they can install the PWA
      setCanInstall(true);
    });
  }, []);

  const onAgree = useCallback(
    async (doNotAskAgain: boolean) => {
      if (!event) return;
      event.prompt();

      const result = await event.userChoice;
      console.log('👍', 'userChoice', result);
      dispatch(
        actionAccountInstalledSet({
          isInstalled: true,
          installDoNotAskAgain: doNotAskAgain,
        }),
      );
    },
    [event, dispatch],
  );
  const onDisagree = useCallback(
    async (doNotAskAgain: boolean) => {
      dispatch(
        actionAccountInstalledSet({
          isInstalled: false,
          installDoNotAskAgain: doNotAskAgain,
        }),
      );
    },
    [dispatch],
  );

  const value = useMemo(
    () => ({
      canInstall,
      isInstalled,
      lastAskDate: installLastDate,
      doNotAskAgain: installDoNotAskAgain,
      onAgree,
      onDisagree,
    }),
    [isInstalled, canInstall, onAgree, onDisagree, installLastDate, installDoNotAskAgain],
  );

  return (
    <InstallDesktopAppContext.Provider value={value}>{children}</InstallDesktopAppContext.Provider>
  );
};
