import { DynamicService } from 'modules/dynamic-service';
import { AppState } from 'store';
import { apiRtk } from 'utils/service';
import { apiTodoTaskCategories, TaskTag } from '../todo-task-categories';
import { API_TODO_TASKS, ToDoTask, ToDoTaskPostInput } from './models';
class Service extends DynamicService<ToDoTask> {
  create = async (input: ToDoTaskPostInput) => {
    const { userEmployeeProfileIDs, ...rest } = input;
    const response = await this.post(rest);

    const id = String(response.data.id);

    if (userEmployeeProfileIDs.length) {
      await this.bulkUserEmployees(id, userEmployeeProfileIDs);
    }

    return { data: response.data };
  };
  bulkUserEmployees = async (id: string, userEmployeeProfileIDs: string[]) => {
    return this.engine.post(API_TODO_TASKS.BULK_EMPLOYEE_IDS, {
      mainID: id,
      itemIDs: userEmployeeProfileIDs,
    });
  };
}

export const ServiceTodoTasks = new Service({
  mainField: 'id',
  getAll: API_TODO_TASKS.GET_ALL_DYNAMIC,
  post: API_TODO_TASKS.POST,
  patch: API_TODO_TASKS.PATCH,
  delete: API_TODO_TASKS.DELETE,
});

export const apiTodoTasks = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    createTodoTask: builder.mutation<ToDoTask, ToDoTaskPostInput>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceTodoTasks.create(input);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    createAutoTodoTaskByTag: builder.mutation<void, TaskTag>({
      queryFn: async (taskTag, { getState, dispatch }) => {
        try {
          const state = getState() as AppState;
          const user = state.accounts.user;

          if (!user) {
            throw new Error('current user is not defined');
          }

          const resultRequest = dispatch(
            apiTodoTaskCategories.endpoints.getTodoTaskCategoryForTaskDataByTag.initiate(taskTag),
          );

          resultRequest.unsubscribe();
          const category = await resultRequest.unwrap();

          const payload = {
            userPatientProfileID: user.appUserID,
            toDoTaskCategoryID: category.id,
            entryDate: new Date().toISOString(),
            message: category.defaultMessage,
            userEmployeeProfileIDs: category.userEmployeeProfileIDs,
          };

          await dispatch(apiTodoTasks.endpoints.createTodoTask.initiate(payload)).unwrap();

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

export * from './models';
