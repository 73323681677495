import { ThemeOptions } from '@mui/material';
import variables from 'styles/config.scss';

const appBreakpoints = {
  xs: Number(variables.xs),
  sm: Number(variables.sm),
  md: Number(variables.md),
  lg: Number(variables.lg),
  xl: Number(variables.xl),
};

export const AppThemeOptions: ThemeOptions = {
  breakpoints: {
    values: appBreakpoints,
  },

  spacing: 10,

  typography: {
    htmlFontSize: 10,
    fontFamily: ['Arial', 'sans-serif'].join(','),
    h1: {},
    h2: {
      fontSize: '3.2rem',
      lineHeight: 1.15,
    },
    h3: {},
    h4: {},
    h5: {
      fontSize: '2.4rem',
      lineHeight: 1.15,
    },
    h6: {
      fontSize: '2rem',
      lineHeight: 1.15,
    },
    subtitle1: {
      fontSize: '1.8rem',
      lineHeight: 1.25,
    },
    subtitle2: {
      fontSize: '1.6rem',
      lineHeight: 1.25,
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: 1.15,
    },
    body2: {
      fontSize: '1.2rem',
      lineHeight: 1.25,
    },
    button: {
      fontSize: '1.4rem',
      lineHeight: 1.15,
      fontWeight: 700,
    },
    caption: {},
    overline: {},
  },

  palette: {
    primary: {
      main: variables.colorPrimary,
    },
    primaryLight: {
      main: variables.colorPrimary10,
      contrastText: variables.colorPrimary,
    },
    greenLight: {
      main: variables.colorGreen10,
      contrastText: variables.colorGreen,
    },
    secondary: {
      main: variables.colorSecondary,
    },
    success: {
      main: variables.colorGreen,
      contrastText: '#fff',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: variables.borderRadius,
        },
        elevation1: {
          boxShadow: variables.boxShadow,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        sizeMedium: {
          minHeight: '4.8rem',
        },
        sizeSmall: {
          minHeight: '2.8rem',
          fontSize: '1.2rem',
        },
        textSizeMedium: {
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 1,
          lineHeight: 1,
        },
      },
    },
  },
};
