import { createSlice } from '@reduxjs/toolkit';
import { Label } from 'services/labels';
import { actionLabelsInit } from './actions';

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  labels: Record<string, Label[]>;
  languageID: string | null;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    labels: {},
    languageID: null,
  };
};

export const labelsSlice = createSlice({
  name: 'LABELS',
  initialState: initState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionLabelsInit.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionLabelsInit.fulfilled, (state, action) => {
      const {
        payload: { languageID, data },
      } = action;
      state.labels[languageID] = data;
      state.languageID = languageID;
      state.isLoading = false;
      state.isInit = true;
    });
    builder.addCase(actionLabelsInit.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
  },
});

export const reducerLabels = labelsSlice.reducer;
