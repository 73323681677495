import { Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { AppFooter } from 'components/footer';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { PrivateBottomNavigation, PrivateHeader } from './components';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  return (
    <div className={clsx(style.root)}>
      <PrivateHeader />
      <main>
        <Outlet />
      </main>
      {isMobile ? <PrivateBottomNavigation /> : <AppFooter />}
    </div>
  );
});
