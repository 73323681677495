import LockPersonIcon from '@mui/icons-material/LockPerson';
import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useNotify, useTranslate } from 'hooks';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionAccountLogout } from 'store/accounts';
import style from './index.module.scss';

interface Props {
  redirect?: string;
}

export const ScreenNotEnoughPermissions: React.FC<Props> = ({ redirect }) => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { spacing } = useTheme();
  const { onError } = useNotify();

  const navigate = useNavigate();

  const onLogin = useCallback(async () => {
    setIsLoading(true);
    const res = await dispatch(actionAccountLogout());

    if ('error' in res) {
      setIsLoading(false);
      onError(res.error);
    }

    navigate(APP_ROUTES.INDEX({ redirect }).config);
  }, [dispatch, onError, navigate, redirect]);

  return (
    <div className={style.root}>
      <Paper
        className={style.card}
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <LockPersonIcon color={'error'} fontSize={'inherit'} sx={{ fontSize: '12rem' }} />
        <Typography variant={'h2'} color={'secondary'} mb={1}>
          {tp('not-enough-permissions-title')}
        </Typography>
        <Box width={'100%'} textAlign={'left'}>
          <Typography variant={'subtitle2'} color={'secondary'}>
            {tp('not-enough-permissions-description')}
          </Typography>
        </Box>
      </Paper>

      <div>
        <Button
          disabled={isLoading}
          variant={'contained'}
          sx={{ minWidth: spacing(32) }}
          onClick={onLogin}
        >
          {tp('relogin')}
        </Button>
      </div>
    </div>
  );
};
