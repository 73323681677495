export const TIME_FORMAT_DATE = 'yyyy-MM-dd';
export const TIME_FORMAT_DATE_TIME = `${TIME_FORMAT_DATE} HH:mm`;
export const FORMAT_DATE = `dd.MM.yyyy`;
export const FORMAT_TIME = `HH:mm`;
export const CURRENCY_SYMBOL = `₪`;

export enum MEDIA_TYPES {
  IMG,
  PDF,
}
