const API = 'ToDoTasks';

export const API_TODO_TASKS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  BULK_EMPLOYEE_IDS: `${API}/CreateUserEmployeeProfileToDoTaskBulk`,
  PATCH: (data: Pick<ToDoTask, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ToDoTask, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ToDoTask extends Components.Schemas.ToDoTask {}
export interface ToDoTaskPostInput extends Omit<ToDoTask, 'id'> {
  userEmployeeProfileIDs: string[];
}
