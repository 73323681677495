import { useTranslate } from 'hooks/use-translate';
import { useMemo } from 'react';

const DEFAULT_LOCALE = 'en';

export const useApexLocales = () => {
  const { tp } = useTranslate();

  const locales = useMemo(() => {
    return [
      {
        name: DEFAULT_LOCALE,
        options: {
          months: [
            tp('january'),
            tp('february'),
            tp('march'),
            tp('april'),
            tp('may'),
            tp('june'),
            tp('july'),
            tp('august'),
            tp('september'),
            tp('october'),
            tp('november'),
            tp('december'),
          ],
          shortMonths: [
            tp('jan'),
            tp('feb'),
            tp('mar'),
            tp('apr'),
            tp('may'),
            tp('jun'),
            tp('jul'),
            tp('aug'),
            tp('sep'),
            tp('oct'),
            tp('nov'),
            tp('dec'),
          ],
          days: [
            tp('sunday'),
            tp('monday'),
            tp('tuesday'),
            tp('wednesday'),
            tp('thursday'),
            tp('friday'),
            tp('saturday'),
          ],
          shortDays: [tp('sun'), tp('mon'), tp('tue'), tp('wed'), tp('thu'), tp('fri'), tp('sat')],
        },
      },
    ];
  }, [tp]);
  const defaultLocale = DEFAULT_LOCALE;

  return { defaultLocale, locales };
};
