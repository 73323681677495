import { FileCloud } from 'modules/file-cloud';
import { downloadBlob } from 'utils/download';
import { fileToBase64, getFileNameFromUrl } from 'utils/file-uploader';
import { getRandomString } from 'utils/other';
import { apiAppProxy, apiRtk, RTK_TAGS } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_MEDIA_PRIVATE, CloudFileViewModel } from './models';

export * from './models';

class Service {
  async downloadFile(filePath: string) {
    const fileName = getFileNameFromUrl(filePath);
    const { data: blob } = await ServiceMediaPrivate.getFile(filePath);
    return downloadBlob(blob, { fileName });
  }
  async getFile(filePath: string) {
    const result = await apiAppProxy.get<Blob>(API_MEDIA_PRIVATE.DOWNLOAD_FILE, {
      params: {
        fileName: filePath,
      },
      responseType: 'blob',
    });

    if (result.status === 204) {
      throw new Error('content-was-not-found');
    }

    return result;
  }
  async removeFile(filePath: string) {
    return apiAppProxy.put(API_MEDIA_PRIVATE.REMOVE_FILE(filePath));
  }
  async uploadFile(data: PatchPartial<CloudFileViewModel, 'fileStreamString'>) {
    const fileName = data.fileName ? data.fileName : getRandomString(20);
    return apiAppProxy.post<CloudFileViewModel>(API_MEDIA_PRIVATE.UPLOAD_FILE, {
      ...data,
      fileName,
    });
  }
}

export const ServiceMediaPrivate = new Service();

export const FileCloudPrivate = new FileCloud(
  async (data, file) => {
    const res = await ServiceMediaPrivate.uploadFile(data as any);

    return res.data;
  },
  async (data) => {
    const res = await ServiceMediaPrivate.removeFile(data.filePath || '');

    return res.data;
  },
);

export const apiMediaUploads = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getFile: build.query<string, string>({
      queryFn: async (filePath) => {
        try {
          const { data } = await ServiceMediaPrivate.getFile(filePath);
          const base64 = await fileToBase64(data);
          return { data: base64 };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, filePath) => [{ type: RTK_TAGS.PRIVATE_FILES, id: filePath }],
    }),
    downloadFile: build.mutation<void, string>({
      queryFn: async (filePath) => {
        try {
          await ServiceMediaPrivate.downloadFile(filePath);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
