import { isEqual, isPlainObject, reduce } from 'lodash';
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const getRandomString = (length = 10, chars = 'aA') => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

export const createMap = <M extends Record<string, any>>(source: M[], key: keyof M) => {
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {} as Record<string, M | undefined>);
};
export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: ArgumentTypes<T>) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};

export const templateValue = (value: string, templates: string[]) => {
  let _templates = Array.from(templates);
  const patterns = {
    '#': /\d/,
  };
  const _value = Array.from(String(value || ''));
  return _value.reduce((result, char, i) => {
    _templates = _templates.filter((template) => {
      // @ts-ignore
      const pattern = patterns[template[i]];
      return pattern ? pattern.test(char) : template[i] === char;
    });
    const isMatch = !!_templates.length;
    result += isMatch ? char : '';
    return result;
  }, '');
};
const replaceLabel = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/#.+?#/gi, (v: string) => {
    const key = v.substring(1, v.length - 1);
    return replacer(key);
  });
};
export const calcRequestLabel = (value: string, replacer: (v: string) => string) => {
  const _value = String(value);
  if (RegExp(/^@.+@$/gi).test(_value)) {
    return replaceLabel(_value.substring(1, _value.length - 1), replacer);
  } else {
    return replacer(_value);
  }
};
const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{.+?}}/gi, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    return payload[key] === undefined ? '-- --' : payload[key];
  });
};

export const fieldToLabelKey = <T extends Record<string, any> = any>(field: keyof T) => {
  return String(field)
    .replace(/ID/g, '')
    .replace(/[A-Z]/g, (substring) => {
      return `-${substring}`;
    })
    .toLowerCase()
    .replace(/^-/gi, '');
};

type AnyFunction = (...args: any[]) => any;
export const composeFunctions = <T extends (...args: any[]) => any>(
  cb: T,
  ...functions: (AnyFunction | undefined)[]
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    (functions.filter(Boolean) as AnyFunction[]).forEach((fn) => {
      fn(...args);
    });
    return result;
  };
};

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const replaceTemplate = (value: string, replacer: (v: string) => any) => {
  return String(value).replace(/{{(.|\n)+?}}/g, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};

interface HtmlTemplateOptions {
  onEmpty?: (key: string) => any;
  onError?: (key: string, error: Error) => any;
}

const defaultOnEmpty = () => '';
const defaultOnError = (key: string, error: Error) => {
  console.error(error);
  return '';
};

export const calcHtmlTemplate = (
  value: string,
  payload: Record<string, any> = {},
  options: HtmlTemplateOptions = {},
) => {
  const config = Object.entries(payload).map(([argName, param]) => ({ argName, param }));
  const { onEmpty = defaultOnEmpty, onError = defaultOnError } = options;

  const argsString = config.map(({ argName }) => argName).join(',');
  const params = config.map(({ param }) => param);

  return replaceTemplate(value, (key) => {
    try {
      // eslint-disable-next-line
      const fun = new Function(argsString, `return ${key}`);
      const result = fun(...params);
      return result === undefined ? onEmpty(key) : result;
    } catch (e: any) {
      return onError(key, e);
    }
  });
};

export const getDiff = <T extends Record<string, any>>(obj1: T, obj2: T) => {
  return reduce(
    obj1,
    function (result, value, key: keyof T) {
      if (isPlainObject(value)) {
        result[key] = getDiff(value, obj2[key]);
      } else if (!isEqual(value, obj2[key])) {
        result[key] = value;
      }
      return result;
    },
    {} as T,
  );
};
