import fontkit from '@pdf-lib/fontkit';
import OpenSans from 'fonts/font-open-sans-bold';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { FORM_DOCUMENT_FIELD_TYPE } from 'services/documents';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { hexaToRgba } from 'utils/colors';
import { workerErrorNotifyThunk } from 'utils/sagas';
import { actionSignDocumentInit, actionSignDocumentSubmit } from './actions';
import {
  selectSignDocumentData,
  selectSignDocumentFieldInputs,
  selectSignDocumentTemplate,
} from './selectors';
import {
  actionSignDocumentSetDocumentFile,
  actionSignDocumentSetPages,
  actionSignDocumentSetProcessing,
  actionSignDocumentUpdateField,
  SignDocumentPage,
} from './slice';

const CHECK_BOX = '✓';

function* sagaUpdatePages() {
  const blobTemplate = yield* select(selectSignDocumentTemplate);
  const doc = yield* select(selectSignDocumentData);

  yield* put(actionSignDocumentSetProcessing(true));

  try {
    if (!doc || !blobTemplate) {
      throw new Error('document-template-is-required');
    }

    const {
      formDocument: { isRTL },
    } = doc;
    const arrayBufferPdf = yield* call(blobTemplate.arrayBuffer.bind(blobTemplate));

    const pdfDoc = yield* call(PDFDocument.load, arrayBufferPdf);

    pdfDoc.registerFontkit(fontkit);

    const font = yield* call(pdfDoc.embedFont.bind(pdfDoc), OpenSans);
    const fontCheckBox = yield* call(pdfDoc.embedFont.bind(pdfDoc), StandardFonts.ZapfDingbats);

    let pages: SignDocumentPage[] = Array.from<SignDocumentPage>({
      length: pdfDoc.getPageCount() || 1,
    }).map((_, i) => ({
      index: i,
      inputs: [],
    }));

    const fieldInputs = yield* select(selectSignDocumentFieldInputs);

    fieldInputs.forEach((item) => {
      const page = typeof item.pageNumber === 'number' ? pages[item.pageNumber] : null;

      if (page) {
        page.inputs.push(item);
      }
    });

    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
      const { index, inputs } = pages[pageIndex];

      if (inputs.length === 0) {
        continue;
      }

      const page = pdfDoc.getPage(index);
      const { height: pageHeight } = page.getSize();

      for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
        const input = inputs[inputIndex];

        const width_ = input.width || 0;
        const height_ = input.height || 0;
        const x = input.coordX || 0;
        const y = input.coordY || 0;

        const inputFontSize = input.fontSize || 0;
        const inputType = input.formDocumentFieldType as unknown as FORM_DOCUMENT_FIELD_TYPE;

        // background
        if (input.background) {
          const [r, g, b, a] = hexaToRgba(input.background);
          page.drawRectangle({
            x,
            y: pageHeight - height_ - y,
            width: width_,
            height: height_,
            color: rgb(r / 255, g / 255, b / 255),
            opacity: a || 1,
          });
        }
        if (!input.defaultValue) {
          continue;
        }

        if (inputType === FORM_DOCUMENT_FIELD_TYPE.Checkbox) {
          const textHeight = fontCheckBox.heightAtSize(inputFontSize);

          page.drawText(CHECK_BOX, {
            x: x + (width_ - fontCheckBox.widthOfTextAtSize(CHECK_BOX, inputFontSize)) * 0.5,
            y: pageHeight - y - height_ * 0.5 - textHeight * 0.3,
            size: inputFontSize,
            lineHeight: inputFontSize * 1.2,
            font: fontCheckBox,
          });

          continue;
        }

        // render Signature
        if (inputType === FORM_DOCUMENT_FIELD_TYPE.Signature) {
          const pngImage = yield* call(pdfDoc.embedPng.bind(pdfDoc), input.defaultValue);

          page.drawImage(pngImage, {
            x,
            y: pageHeight - height_ - y,
            width: width_,
            height: height_,
          });

          continue;
        }

        // render else
        const textHeight = font.heightAtSize(inputFontSize);
        const text = String(input.defaultValue);

        page.drawText(text, {
          x: isRTL ? x + width_ - font.widthOfTextAtSize(text, inputFontSize) : x,
          y: pageHeight - y - height_ * 0.5 - textHeight * 0.25,
          size: inputFontSize,
          lineHeight: inputFontSize * 1.2,
          font,
        });
      }
    }
    const pdfBytes = yield* call(pdfDoc.save.bind(pdfDoc));

    yield* put(actionSignDocumentSetPages(pages));
    yield* put(actionSignDocumentSetDocumentFile(pdfBytes));
    yield* put(actionSignDocumentSetProcessing(false));
  } catch (e) {
    yield* put(actionSignDocumentSetProcessing(false));
  }
}
export const sagasSignDocument = [
  takeLatest([actionSignDocumentInit.fulfilled, actionSignDocumentUpdateField], sagaUpdatePages),
  takeLatest(
    [actionSignDocumentSubmit.rejected, actionSignDocumentInit.rejected],
    workerErrorNotifyThunk,
  ),
];
