import { addMonths, format } from 'date-fns';
import { CurrentAppUser } from 'services/accounts';
import { PatientSubscription } from 'services/subscription';
import { convertToDate } from 'utils/dates';
import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import {
  apiAppProxy,
  apiRtk,
  DynamicResult,
  RTK_TAGS,
  transformResponseDynamic,
} from 'utils/service';
import {
  API_DOCUMENTS,
  Document,
  FORM_DOCUMENT_FIELD_TYPE,
  GetDocumentModel,
  GetFormDocumentsModel,
  IDocument,
  SetFieldInputDefaultValueOptions,
  SignDocumentInput,
  SignDocumentMap,
} from './models';

export enum DOCUMENT_STATUS {
  SIGNED = 2,
  NOT_SIGNED,
  EXPIRED,
}

export const getDocumentStatus = (
  doc: Pick<Components.Schemas.UserPatientProfileFormDocument, 'signedDate' | 'expiredDate'>,
) => {
  const { signedDate, expiredDate: _expiredDate } = doc;
  if (signedDate) return DOCUMENT_STATUS.SIGNED;
  const nowDate = new Date();
  const expiredDate = convertToDate(_expiredDate);
  return expiredDate.getTime() < nowDate.getTime()
    ? DOCUMENT_STATUS.EXPIRED
    : DOCUMENT_STATUS.NOT_SIGNED;
};

interface AdapterDocumentSignInput extends Omit<Partial<CurrentAppUser>, 'startDate'> {
  subscription?: Pick<PatientSubscription, 'startDate' | 'endDate'>;
}

class Service {
  async signDocument(input: SignDocumentInput) {
    const { id, formDocumentPath } = input;
    return apiAppProxy.patch(API_DOCUMENTS.PATCH_FORM_DOCUMENTS(id), {
      formDocumentPath,
      signedDate: new Date(),
    });
  }
  async getFormDocument(documentID: string) {
    const dynamic = dynamicNamespace<Components.Schemas.UserPatientProfileFormDocument>();

    const {
      data: { value },
    } = await apiAppProxy.get<DynamicResult<GetDocumentModel>>(API_DOCUMENTS.GET_FORM_DOCUMENTS, {
      params: {
        select: dynamic.select(
          'id',
          'expiredDate',
          'signedDate',
          'formDocumentPath',
          'new { formDocument.title, formDocument.pdfUrl, formDocument.isRTL } as formDocument',
          'formDocument.formDocumentInputs as formDocumentInputs',
        ),
        filter: mergeFilters(dynamic.makeFilter('id', documentID, equals)).join('&&'),
        take: 1,
      },
    });

    if (!value[0]) {
      throw new Error('record-not-found');
    }

    return value[0];
  }
  adapterDocumentSign(userInfo: AdapterDocumentSignInput): SignDocumentMap {
    const { subscription, ...currentUser } = userInfo;

    return {
      firstName: currentUser.firstName || '',
      lastName: currentUser.lastName || '',
      fullName: [currentUser.firstName, currentUser.lastName].filter(Boolean).join(' '),
      idNumber: currentUser.idNumber || '',
      dateOfBirth: currentUser.dateOfBirth || '',
      mobilePhone: currentUser.mobilePhone || '',
      email: currentUser.email || '',
      address: currentUser.address || '',

      familyMemberName:
        currentUser.familyMemberName ||
        [currentUser.familyMemberFirstName, currentUser.familyMemberLastName]
          .filter(Boolean)
          .join(' '),
      familyMemberFirstName: currentUser.familyMemberFirstName || '',
      familyMemberLastName: currentUser.familyMemberLastName || '',
      familyMemberPhone: currentUser.familyMemberPhone || '',
      familyMemberIDNumber: currentUser.familyMemberIDNumber || '',
      familyMemberRelationship: currentUser.familyMemberRelationship || '',

      startDate: subscription?.startDate || new Date(),
      endDate: subscription?.endDate || addMonths(new Date(), 3),
      docDate: new Date(),
      docTime: new Date(),
    };
  }
  prepareDocumentFieldInputs(
    fieldInputs: Components.Schemas.FormDocumentInput[],
    documentMap: SignDocumentMap,
  ) {
    return fieldInputs.map((fieldInput) => {
      const key = fieldInput.inputID as keyof typeof documentMap;
      return {
        ...fieldInput,
        defaultValue: ServiceDocuments.setFieldInputDefaultValue({
          fieldInput,
          value: documentMap[key],
        }),
      };
    });
  }
  setFieldInputDefaultValue(options: SetFieldInputDefaultValueOptions) {
    const { fieldInput, value } = options;

    const inputType = fieldInput.formDocumentFieldType as unknown as FORM_DOCUMENT_FIELD_TYPE;

    if (inputType === FORM_DOCUMENT_FIELD_TYPE.Checkbox) {
      return value === undefined ? null : value;
    }

    if (inputType === FORM_DOCUMENT_FIELD_TYPE.Date) {
      let _format = 'dd.MM.yyyy';

      if (fieldInput.inputID === 'docTime') {
        _format = 'HH:mm';
      }

      return value ? format(convertToDate(value), _format) : null;
    }

    return value;
  }
}

export const ServiceDocuments = new Service();

export * from './models';

export const apiDocuments = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getDocuments29: build.query<Document[], void>({
        query: () => {
          const dynamic = dynamicNamespace<IDocument>();
          return {
            url: API_DOCUMENTS.GET_PRESCRIPTIONS,
            params: {
              select: [
                dynamic.select(
                  'id',
                  'createdDate as createdDate',
                  'fileName',
                  'documentURL',
                  'userEmployeeProfile.fullName as employeeFullName',
                  'documentType.title as documentTypeTitle',
                ),
              ].join(','),
              filter: mergeFilters(
                dynamic.makeFilter('isForm29', true, equals),
                dynamic.makeFilter('isActive', true, equals),
              ).join('&&'),
              orderBy: dynamic.orderBy('createdDate', 'desc'),
            },
          };
        },
        transformResponse: transformResponseDynamic,
      }),
      getDocumentsOther: build.query<Document[], void>({
        query: () => {
          const dynamic = dynamicNamespace<IDocument>();
          return {
            url: API_DOCUMENTS.GET_PRESCRIPTIONS,
            params: {
              select: [
                dynamic.select(
                  'id',
                  'createdDate as createdDate',
                  'fileName',
                  'documentURL',
                  'userEmployeeProfile.fullName as employeeFullName',
                  'documentType.title as documentTypeTitle',
                ),
              ].join(','),
              filter: mergeFilters(
                dynamic.makeFilter('isForm29', false, equals),
                dynamic.makeFilter('isActive', true, equals),
              ).join('&&'),
              orderBy: dynamic.orderBy('createdDate', 'desc'),
            },
          };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFormDocuments: build.query<GetFormDocumentsModel[], void>({
        query: () => {
          const dynamic = dynamicNamespace<Components.Schemas.UserPatientProfileFormDocument>();
          return {
            url: API_DOCUMENTS.GET_FORM_DOCUMENTS,
            params: {
              select: dynamic.select(
                'id',
                'expiredDate',
                'signedDate',
                'formDocumentPath',
                'formDocument.formDocumentCategory.title',
              ),
              orderBy: dynamic.orderBy('expiredDate', 'desc'),
            },
          };
        },
        transformResponse: transformResponseDynamic,
        providesTags: [{ type: RTK_TAGS.SIGN_DOCUMENTS, id: 'LIST' }],
      }),
    };
  },
});
