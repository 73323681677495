const API = 'ToDoTaskCategories';

export const API_TODO_TASK_CATEGORIES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ToDoTaskCategory, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ToDoTaskCategory, 'id'>) => `${API}/Delete/${data.id}`,
};

export enum TaskTag {
  PatientAppAskPrescription = 1,
  PatientAppAddingDocument = 2,
  PatientAppAddDietaryItem = 3,
}

export interface ToDoTaskCategory extends Omit<Components.Schemas.ToDoTaskCategory, 'taskTag'> {
  taskTag: null | TaskTag;
}

export interface TodoTaskCategoryForTaskData
  extends Pick<ToDoTaskCategory, 'id' | 'title' | 'defaultMessage'> {
  userEmployeeProfileIDs: string[];
}
