import { AppTranslator } from 'modules/i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
export const useTranslate = () => {
  const { t } = useTranslation();

  const translator = useMemo(() => {
    return new AppTranslator({ t });
  }, [t]);

  return { t: translator.t, tp: translator.tp };
};
