const API = 'ErrorHelpers';

export const API_ERROR_HELPERS = {
  SEND_ERROR: `${API}/SendError`,
};
export interface SendErrorInput {
  userPatientProfileID: string;
  message: string;
  stack: string;
}
