import { UserEmployeeProfile, UserPatientProfile } from 'services/activities';

const API = 'Documents';

export const API_DOCUMENTS = {
  GET_PRESCRIPTIONS: `${API}/GetDocumentsDynamic`,
  GET_FORM_DOCUMENTS: `${API}/GetFormDocumentsDynamic`,
  PATCH_FORM_DOCUMENTS: (id: string) => `${API}/PatchFormDocument/${id}`,
};
export interface IDocument {
  id: string;
  createdDate: string;
  isActive: true;
  userPatientProfileID: string;
  userEmployeeProfileID: string;
  documentTypeID: string;
  fileName: string;
  documentURL: string;
  isForm29: true;
  userPatientProfile: UserPatientProfile;
  userEmployeeProfile: UserEmployeeProfile;
  documentType: DocumentType;
}

export interface Document
  extends Pick<IDocument, 'id' | 'createdDate' | 'fileName' | 'documentURL'> {
  employeeFullName: UserEmployeeProfile['fullName'];
  documentTypeTitle: DocumentType['title'];
}
interface DocumentType {
  id: string;
  title: string;
}

export interface GetFormDocumentsModel
  extends Pick<
    Components.Schemas.UserPatientProfileFormDocument,
    'id' | 'expiredDate' | 'signedDate' | 'formDocumentPath'
  > {
  id: string;
  expiredDate: string;
  title: string;
}

export interface GetDocumentModel
  extends Pick<
    Components.Schemas.UserPatientProfileFormDocument,
    'id' | 'expiredDate' | 'userPatientProfileID' | 'signedDate' | 'formDocumentPath'
  > {
  formDocument: Pick<Components.Schemas.FormDocument, 'title' | 'pdfUrl' | 'isRTL'>;
  formDocumentInputs: Components.Schemas.FormDocumentInput[];
}

export enum FORM_DOCUMENT_FIELD_TYPE {
  Text,
  Date,
  Checkbox,
  Signature,
  IDNumber,
}

export interface SignDocumentMap {
  firstName: string;
  lastName: string;
  fullName: string;
  idNumber: string;
  dateOfBirth: string;
  mobilePhone: string;
  email: string;
  familyMemberName: string;
  familyMemberFirstName: string;
  familyMemberLastName: string;
  familyMemberPhone: string;
  familyMemberIDNumber: string;
  familyMemberRelationship: string;
  address: string;

  // signature
  startDate: Date | string;
  endDate: Date | string;
  docDate: Date;
  docTime: Date;
}

export interface SetFieldInputDefaultValueOptions {
  fieldInput: Components.Schemas.FormDocumentInput;
  value: any;
}

export interface SignDocumentInput {
  id: string;
  formDocumentPath: string;
}
