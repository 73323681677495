import { APP_ROUTES } from 'configs';
import { AxiosInterceptorPatch } from 'contexts/axios-interceptors/patch';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServiceAccounts } from 'services/accounts';
import { getAuthTokens, updateAuthTokens } from 'utils/cookies';
import { apiApp, apiAppProxy } from 'utils/service';
import { AxiosExpiresToken } from './expires-token';
import { AxiosInterceptorsRefreshToken } from './refresh-token';
import { AxiosInterceptorsToken } from './token';

const tokenInstances = [apiAppProxy, apiApp];
const expiresTokenInstances = [apiApp];

export const AxiosInterceptorsProvider: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>();
  const onRefresh = useCallback(async () => {
    const { token: Token, refreshToken: RefreshToken, expires } = getAuthTokens();

    if (!Token || !RefreshToken || !expires) {
      throw new Error('token-not-found');
    }

    const {
      data: { token, refreshToken },
    } = await ServiceAccounts.refreshToken({
      token: Token,
      refreshToken: RefreshToken,
    });

    return { token, refreshToken, expires };
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (error) {
      updateAuthTokens();
      navigate(APP_ROUTES.INDEX({ redirect: window.location.origin }).config, { replace: true });
      setError(undefined);
    }
  }, [error, navigate]);

  const refresh = useCallback(async () => {
    const res = await onRefresh();
    updateAuthTokens(res);
  }, [onRefresh]);

  return (
    <AxiosExpiresToken instances={expiresTokenInstances} refresh={refresh}>
      <AxiosInterceptorsToken instances={tokenInstances}>
        <AxiosInterceptorsRefreshToken
          instances={tokenInstances}
          onRefresh={onRefresh}
          onError={setError}
          onSuccess={updateAuthTokens}
        >
          <AxiosInterceptorPatch>{children}</AxiosInterceptorPatch>
        </AxiosInterceptorsRefreshToken>
      </AxiosInterceptorsToken>
    </AxiosExpiresToken>
  );
};
