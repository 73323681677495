import { ValueFileUploader } from 'utils/file-uploader';
import * as yup from 'yup';

const API = 'UploadedFiles';

export const API_UPLOADED_FILES = {
  GET: `${API}/GetUploadedFilesDynamic`,
  POST: `${API}/UploadUserFile`,
  DELETE: (data: Pick<UploadedFile, 'id'>) => `${API}/DeleteUserFile`,
};

export const schemaUploadedFile = yup.object({
  title: yup.string().nullable().required('media-title-rule-required'),
  fileURL: yup.lazy((value) => {
    switch (typeof value) {
      case 'string': {
        return yup.string().nullable().required('media-file-rule-required');
      }
      default: {
        return yup.object({
          value: yup.string().nullable().required('media-file-rule-required'),
        });
      }
    }
  }),
});

export interface UploadedFile extends Components.Schemas.UserPatientProfileUploadedFile {
  id: string;
  title: string;
}

export interface UploadedFileInput extends yup.InferType<typeof schemaUploadedFile> {
  fileURL: ValueFileUploader;
  isImage: boolean;
  userPatientProfileID: string;
}
