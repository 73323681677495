import { AxiosInstance } from 'axios';
import { differenceInMinutes } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { getAuthTokens, parseJwt } from 'utils/cookies';

let promise: Promise<any> | null = null;
let isRefreshing = false;

const REVALIDATE_TIME_IN_MINUTES = 5;

type JwtData = {
  exp: number;
};

interface Props {
  instances: AxiosInstance[];
  refresh: () => Promise<any>;
}

export const AxiosExpiresToken: React.FC<Props> = ({ children, instances, refresh }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    const ids = instances.map((instance) => {
      const id = instance.interceptors.request.use(async (config) => {
        if (promise) {
          await promise;
          return config;
        }

        const { token } = getAuthTokens();
        if (!token) return config;

        const jwtData = parseJwt<JwtData>(token);
        if (!jwtData || !jwtData.exp) return config;

        const expired = new Date(jwtData.exp * 1000);
        const dif = differenceInMinutes(expired, new Date());

        if (dif < REVALIDATE_TIME_IN_MINUTES && !isRefreshing) {
          isRefreshing = true;
          promise = new Promise(async (resolve) => {
            try {
              await refresh();
              resolve(null);
            } finally {
              isRefreshing = false;
              promise = null;
            }
          });

          await promise;
        }

        return config;
      });

      return { id, instance };
    });

    setInit(true);

    return () => {
      ids.forEach(({ instance, id }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, [instances, refresh]);

  return <>{init && children}</>;
};
