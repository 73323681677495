import { useAppDispatch } from 'hooks/redux';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { actionAccountGetUser, selectAccountStatuses } from 'store/accounts';

export const AuthProvider: React.FC = ({ children }) => {
  const { isInit } = useSelector(selectAccountStatuses);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isInit) {
      dispatch(actionAccountGetUser({ isBackground: false }));
    }
  }, [isInit, dispatch]);

  return <>{children}</>;
};
