import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

interface Props extends SvgIconProps {}
export function SvgReceipt(props: Props) {
  return (
    <SvgIcon {...props}>
      <g mask="url(#mask0_4374_15846)">
        <path
          d="M6 22C5.16667 22 4.45833 21.7083 3.875 21.125C3.29167 20.5417 3 19.8333 3 19V16H6V2L7.5 3.5L9 2L10.5 3.5L12 2L13.5 3.5L15 2L16.5 3.5L18 2L19.5 3.5L21 2V19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22H6ZM18 20C18.2833 20 18.5207 19.904 18.712 19.712C18.904 19.5207 19 19.2833 19 19V5H8V16H17V19C17 19.2833 17.096 19.5207 17.288 19.712C17.4793 19.904 17.7167 20 18 20ZM9 9V7H15V9H9ZM9 12V10H15V12H9ZM17 9C16.7167 9 16.4793 8.904 16.288 8.712C16.096 8.52067 16 8.28333 16 8C16 7.71667 16.096 7.479 16.288 7.287C16.4793 7.09567 16.7167 7 17 7C17.2833 7 17.5207 7.09567 17.712 7.287C17.904 7.479 18 7.71667 18 8C18 8.28333 17.904 8.52067 17.712 8.712C17.5207 8.904 17.2833 9 17 9ZM17 12C16.7167 12 16.4793 11.904 16.288 11.712C16.096 11.5207 16 11.2833 16 11C16 10.7167 16.096 10.479 16.288 10.287C16.4793 10.0957 16.7167 10 17 10C17.2833 10 17.5207 10.0957 17.712 10.287C17.904 10.479 18 10.7167 18 11C18 11.2833 17.904 11.5207 17.712 11.712C17.5207 11.904 17.2833 12 17 12Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
