import { DynamicService } from 'modules/dynamic-service';
import { dynamicNamespace, equals } from 'utils/dynamic';
import { apiRtk, transformResponseDynamic } from 'utils/service';
import { API_USER_PATIENT_PROFILE_SESSION_ITEMS, GetAnswersBySessionIDItem } from './models';

const dynamic = dynamicNamespace<Components.Schemas.UserPatientProfileSessionItem>();

class Service extends DynamicService<Components.Schemas.UserPatientProfileSessionItem> {
  makeInternalSystemValue(data: any) {
    if (data === undefined || data === null) {
      return null;
    }
    return JSON.stringify({ value: data });
  }
}

export const ServiceUserPatientProfileSessionItems = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SESSION_ITEMS.POST,
  patch: API_USER_PATIENT_PROFILE_SESSION_ITEMS.PATCH,
  delete: API_USER_PATIENT_PROFILE_SESSION_ITEMS.DELETE,
});

export const apiUserPatientProfileSessionItems = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getAnswersBySessionID: build.query<GetAnswersBySessionIDItem[], { sessionID: string }>({
        query: ({ sessionID }) => ({
          url: API_USER_PATIENT_PROFILE_SESSION_ITEMS.GET_ALL_DYNAMIC,
          params: {
            filter: dynamic.makeFilter('userPatientProfileSessionID', sessionID, equals),
            select: dynamic.select(
              'id',
              'rank',
              'entryValueString',
              'new { fieldInput.labelKey } as fieldInput',
            ),
            orderBy: dynamic.orderBy('rank', 'asc'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
    };
  },
});

export * from './models';
