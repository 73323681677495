import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentAppUser, UserPermissions } from 'services/accounts';
import { DateValue } from 'utils/dates';
import { actionAccountGetUser, actionAccountLogout, actionAccountUpdateUser } from './actions';

interface InitState {
  isInit: boolean;
  isLoading: boolean;
  error: null | Error;
  user: null | CurrentAppUser;
  isInstalled: boolean;
  installLastDate: DateValue | null;
  installDoNotAskAgain: boolean;

  notificationsDoNotAskAgain: boolean;

  permissions: UserPermissions | null;
}

const initStateAccount = (): InitState => {
  return {
    isInit: false,
    isLoading: false,
    error: null,
    user: null,
    isInstalled: false,
    installLastDate: null,
    installDoNotAskAgain: false,

    notificationsDoNotAskAgain: false,
    permissions: null,
  };
};

const slice = createSlice({
  name: 'ACCOUNTS',
  initialState: initStateAccount(),
  reducers: {
    actionAccountInstalledSet(
      state,
      action: PayloadAction<{ isInstalled: boolean; installDoNotAskAgain: boolean }>,
    ) {
      state.isInstalled = action.payload.isInstalled;
      state.installDoNotAskAgain = action.payload.installDoNotAskAgain;
      state.installLastDate = new Date().toISOString();
    },
    actionAccountNotificationsSet(
      state,
      action: PayloadAction<{ notificationsDoNotAskAgain: boolean }>,
    ) {
      state.notificationsDoNotAskAgain = action.payload.notificationsDoNotAskAgain;
    },

    actionAccountSetPermissions_TODO(state, payload: PayloadAction<UserPermissions>) {
      state.permissions = { ...state.permissions, ...payload.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionAccountUpdateUser.pending, (state, action) => {
      if (state.user) {
        state.user = { ...state.user, ...action.meta.arg };
      }
    });

    builder
      .addCase(actionAccountGetUser.pending, (state, action) => {
        if (!action.meta.arg.isBackground) {
          state.isLoading = true;
        }
        state.error = null;
      })
      .addCase(actionAccountGetUser.fulfilled, (state, action) => {
        const user = action.payload;

        state.isLoading = false;
        state.isInit = true;
        state.user = user;
      })
      .addCase(actionAccountGetUser.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder
      .addCase(actionAccountLogout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionAccountLogout.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(actionAccountLogout.fulfilled, (state, action) => {
        return initStateAccount();
      });
  },
});

const actions = slice.actions;
export const {
  actionAccountInstalledSet,
  actionAccountNotificationsSet,
  actionAccountSetPermissions_TODO,
} = actions;
export const reducerAccounts = slice.reducer;
