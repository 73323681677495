const API = 'UserPatientProfileWebPushNotifications';

export const API_WEB_PUSH = {
  GET_KEYS: '/Notifications/GetWebKey',
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserPatientProfileWebPushNotification, 'id'>) =>
    `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserPatientProfileWebPushNotification, 'id'>) =>
    `${API}/Delete/${data.id}`,
};

export interface WebPushSubscribeInput {
  userPatientProfileID: string;
  subscription: PushSubscription;
}
