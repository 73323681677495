import { select } from 'modules/typed-saga';
import { toast } from 'react-toastify';
import { Action } from 'redux';
import { selectLabelsMap } from 'store/labels';
import { calcRequestLabel } from 'utils/other';
import { parseErrorData } from 'utils/service';

interface ThunkAction<E extends { message: string }> extends Action<string> {
  error: E;
}
export function* workerErrorNotifyThunk<T extends ThunkAction<Error>>(data: T) {
  if (data.error) {
    yield* notifyErrorSaga(data.error);
  }
}

interface SagaAction<E extends { message: string }> extends Action<any> {
  payload: { error: E };
}
export function* workerErrorNotifySaga<T extends SagaAction<Error>>(action: T) {
  if (action.payload.error) {
    yield* notifyErrorSaga(action.payload.error);
  }
}

export function* notifyErrorSaga(e: { message: string }) {
  const error = parseErrorData(e);
  const mapLabels = yield* select(selectLabelsMap);

  const title = calcRequestLabel(error.message, (key) => {
    return mapLabels[key]?.title || key;
  });

  yield toast.error(title);
}

export const combineAction = <R, S, F>(pending: R, fulfilled: S, rejected: F) => {
  return { pending, fulfilled, rejected };
};
