import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FlatwareIcon from '@mui/icons-material/Flatware';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import HistoryIcon from '@mui/icons-material/History';
import { Stack } from '@mui/material';
import clsx from 'clsx';
import { SvgReceipt } from 'components/svg-icon/receipt';
import { APP_ROUTES, DOCUMENTS_TABS, HISTORY_MEETINGS_TABS } from 'configs';
import { useAppSelector, useTranslate } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { matchPath, To, useLocation, useNavigate } from 'react-router-dom';
import { selectAccountUserUnsignedBadge } from 'store/accounts';
import { NavigationItem } from '../navigation-item';
import style from './index.module.scss';

interface Props {
  isHeader?: boolean;
}

export const PrivateNavigation: React.FC<Props> = ({ isHeader }) => {
  const { tp } = useTranslate();
  const badgeUnsigned = useAppSelector(selectAccountUserUnsignedBadge);
  const items = useMemo(() => {
    return [
      {
        title: tp('dashboard'),
        icon: <DashboardIcon />,
        path: APP_ROUTES.HOME,
        to: APP_ROUTES.HOME().config,
      },
      {
        title: tp('prescriptions'),
        icon: <SvgReceipt />,
        path: APP_ROUTES.PRESCRIPTIONS,
        to: APP_ROUTES.PRESCRIPTIONS().config,
      },
      {
        title: tp('diet-diary'),
        icon: <FlatwareIcon />,
        path: APP_ROUTES.DIET_DIARY,
        to: APP_ROUTES.DIET_DIARY().config,
      },
      {
        title: tp('documents'),
        icon: <FolderOpenIcon />,
        path: APP_ROUTES.DOCUMENTS,
        to: APP_ROUTES.DOCUMENTS({ tab: DOCUMENTS_TABS.MEDIA }).config,
        badge: badgeUnsigned,
      },
      {
        title: tp('history-meetings'),
        icon: <HistoryIcon />,
        path: APP_ROUTES.HISTORY_MEETINGS,
        to: APP_ROUTES.HISTORY_MEETINGS({ tab: HISTORY_MEETINGS_TABS.CLINICAL_MEETINGS }).config,
      },
      {
        title: tp('profile'),
        icon: <AccountCircleIcon />,
        path: APP_ROUTES.PROFILE,
        to: APP_ROUTES.PROFILE().config,
      },
    ];
  }, [tp, badgeUnsigned]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const factoryOnClick = useCallback(
    (to: To) => {
      return () => navigate(to);
    },
    [navigate],
  );

  return (
    <Stack className={clsx(style.root, isHeader && style.rootHeader)} direction={'row'} spacing={0}>
      {items.map((item, i) => {
        const isActive = Boolean(matchPath(item.path, pathname));
        return (
          <NavigationItem
            key={i}
            icon={item.icon}
            title={item.title}
            onClick={factoryOnClick(item.to)}
            isActive={isActive}
            classes={{
              root: clsx(isHeader && style.itemRoot),
              inner: clsx(isHeader && style.itemInner),
              title: clsx(isHeader && style.itemTitle),
            }}
            BadgeProps={{
              badgeContent: item.badge,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          />
        );
      })}
    </Stack>
  );
};
