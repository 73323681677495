import { format, isValid, toDate as fnsToDate } from 'date-fns';
import { FORMAT_DATE } from '../configs';

export type DateValue = Date | string | number | null | undefined;

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export const dateFormat = (
  value: DateValue,
  _format: string = FORMAT_DATE,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
) => {
  if (isDateLike(value)) {
    return format(convertToDate(value), _format, options);
  }
  return '--';
};
