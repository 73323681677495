import { AxiosInstance, AxiosResponse } from 'axios';
import * as dynamic from 'utils/dynamic';
import { apiAppProxy } from 'utils/service';
import { ConditionPartial, PatchPartial } from 'utils/types';
import { DynamicParams, DynamicResult } from './models';

export * from './models';

interface DynamicModel {
  id: string;
}

interface DynamicServiceOptions<M> {
  engine?: AxiosInstance;
  getAll: string;
  post: string;
  patch: (data: PatchPartial<M, keyof M>) => string;
  delete: (data: PatchPartial<M, keyof M>) => string;
}

export class DynamicService<M = DynamicModel> {
  public engine: AxiosInstance = apiAppProxy;
  public urlGetAll: string;
  public urlPost: string;
  public urlPatch: (data: PatchPartial<M, keyof M>) => string;
  public urlDelete: (data: PatchPartial<M, keyof M>) => string;
  public mainField: string;

  constructor(
    options: DynamicServiceOptions<M> & ConditionPartial<M, DynamicModel, { mainField: keyof M }>,
  ) {
    const { getAll, patch, post, engine = this.engine, mainField } = options;

    this.mainField = String(mainField || 'id');

    this.engine = engine;

    this.urlGetAll = getAll;
    this.urlPatch = patch;
    this.urlPost = post;
    this.urlDelete = options.delete;

    this.getAllDynamic = this.getAllDynamic.bind(this);
    this.getDynamic = this.getDynamic.bind(this);
    this.patch = this.patch.bind(this);
    this.post = this.post.bind(this);
    this.delete = this.delete.bind(this);
  }

  async getAllDynamic<Model = M, Params extends DynamicParams = DynamicParams>(params?: Params) {
    return this.engine.get<DynamicResult<Model, Params>>(this.urlGetAll, { params });
  }

  async getDynamic<Model = M, Params extends DynamicParams = DynamicParams>(
    id: string,
    params?: Params,
  ) {
    const response = await this.getAllDynamic<Model>({
      ...params,
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter<{ id: string }>(this.mainField as any, id, dynamic.equals),
          params?.filter,
        )
        .join('&&'),
      take: 1,
    });

    return DynamicService.normalizeResponseSingle(response);
  }

  async patch(data: Partial<M>) {
    return this.engine.patch(this.urlPatch(data as any), { ...data, [this.mainField]: undefined });
  }

  async post(data: M) {
    return this.engine.post<M>(this.urlPost, data);
  }

  async delete(data: Partial<M>) {
    return this.engine.delete(this.urlDelete(data as any));
  }

  static normalizeResponseSingle<T>(response: AxiosResponse<DynamicResult<T>>) {
    const data = response.data.value[0];

    if (!data) {
      throw new Error('record-not-found');
    }

    return { ...response, data };
  }
  static normalizeResponseSingleMaybe<T>(response: AxiosResponse<DynamicResult<T>>) {
    const data = response.data.value[0];

    return { ...response, data: data || null };
  }
}
