import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiDocuments, ServiceDocuments } from 'services/documents';
import { ServiceMediaPrivate } from 'services/media-uploads';
import { PatientSubscription, ServiceSubscriptions } from 'services/subscription';
import { selectAccountUser } from 'store/accounts';
import { select } from 'utils/dynamic';
import { base64ToFileStream, fileToBase64 } from 'utils/file-uploader';
import { parseErrorData, RTK_TAGS } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { selectSignDocumentData, selectSignDocumentFile } from './selectors';
import { SignDocument } from './slice';

export const actionSignDocumentInit = createAsyncThunk<
  { document: SignDocument; documentTemplate: Blob },
  { documentID: string },
  AppAsyncThunkConfig
>(`SIGN_DOCUMENT/init`, async ({ documentID }, { getState }) => {
  try {
    const document = await ServiceDocuments.getFormDocument(documentID);

    const documentUrl = document.formDocument.pdfUrl;

    if (!documentUrl) {
      throw new Error('unexpected-behavior');
    }

    const [{ data: subscription }, { data: documentTemplate }] = await Promise.all([
      ServiceSubscriptions.getLatestSubscriptionIfExistDynamic<
        Pick<PatientSubscription, 'id' | 'startDate' | 'endDate'>
      >({
        select: select('id', 'startDate', 'endDate'),
      }),
      ServiceMediaPrivate.getFile(documentUrl),
    ]);

    const currentUser = selectAccountUser(getState());

    if (!currentUser) {
      throw new Error('unexpected-behavior');
    }

    const docMap = ServiceDocuments.adapterDocumentSign({
      ...currentUser,
      subscription: subscription ?? undefined,
    });
    const formDocumentInputs = ServiceDocuments.prepareDocumentFieldInputs(
      document.formDocumentInputs,
      docMap,
    );

    return { document: { ...document, formDocumentInputs }, documentTemplate };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionSignDocumentSubmit = createAsyncThunk<
  { filePath: string; base64: string },
  void,
  AppAsyncThunkConfig
>(`SIGN_DOCUMENT/submit`, async (_, { getState, dispatch }) => {
  try {
    const documentFile = selectSignDocumentFile(getState());
    const initData = selectSignDocumentData(getState());

    if (!documentFile || !initData) {
      throw new Error('unexpected-behavior');
    }

    const base64 = await fileToBase64(new Blob([documentFile]));

    const {
      data: { filePath },
    } = await ServiceMediaPrivate.uploadFile({
      fileName: `${initData.formDocument.title}-${initData.id}.pdf`,
      fileStreamString: base64ToFileStream(base64),
    });
    await ServiceDocuments.signDocument({
      id: String(initData.id),
      formDocumentPath: filePath,
    });

    // refresh list
    await dispatch(
      apiDocuments.util.invalidateTags([{ type: RTK_TAGS.SIGN_DOCUMENTS, id: 'LIST' }]),
    );

    return { filePath, base64 };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
