import pick from 'lodash/pick';
import { getDiff } from 'utils/other';
import * as yup from 'yup';

export type ChangeLogConfig<Def extends Record<string, any>> = {
  select: string;
  getValue: (res: Def) => string;
};

type Options<FormData, Def> = {
  formData: FormData;
  initData: FormData;

  getDefinition: (formData: FormData, params: { select: string }) => Promise<Def>;
  update: (formData: FormData) => Promise<any>;
};

export const makeChangeLog = async <FormData extends Record<string, any>, Def>(
  schema: Record<keyof FormData, ChangeLogConfig<Def>>,
  options: Options<FormData, Def>,
) => {
  const { formData, initData, getDefinition, update } = options;
  const formKeys = Object.keys(formData);

  const pickInitData = pick(initData, ...formKeys);
  const diffUpdatedData = getDiff(formData, pickInitData as typeof formData);

  const updatedKeys = Object.keys(diffUpdatedData);

  const select = Object.values(pick(schema, ...updatedKeys))
    .map((conf) => {
      return conf.select;
    })
    .join(',');

  // get for log before patch
  const definitionBefore = await getDefinition(formData, { select });

  // patch model
  await update(formData);

  // get for log after patch
  const definitionAfter = await getDefinition(formData, { select });

  const changes = updatedKeys
    .filter((key) => key in schema)
    .map((key) => {
      const conf = schema[key];
      const from = conf.getValue(definitionBefore);
      const fromValue = initData[key];
      const to = conf.getValue(definitionAfter);
      const toValue = formData[key];

      return { field: key as keyof FormData, from, fromValue, to, toValue };
    });

  return changes;
};

export const schemaChangeLog = yup.object({
  field: yup.string(),
  fromValue: yup.string(),
  from: yup.string(),
  to: yup.string(),
  toValue: yup.string(),
});

export const schemaChangeLogs = yup.object({
  fields: yup.array().of(schemaChangeLog),
});
