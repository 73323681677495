import clsx from 'clsx';
import { Logo } from 'components/logo';
import React from 'react';
import { apiPages } from 'services/pages';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const Header: React.FC<Props> = ({ className }) => {
  const { data } = apiPages.useGetPageLayoutQuery();

  return (
    <div className={clsx(style.root, className)}>
      <div className={clsx('container', style.inner)}>
        <Logo>{data?.slogan}</Logo>
      </div>
    </div>
  );
};
