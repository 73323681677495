import { Box, BoxProps, CircularProgress, CircularProgressProps } from '@mui/material';
import React from 'react';

interface Props extends BoxProps {
  ProgressProps?: Partial<CircularProgressProps>;
}
export const Loading: React.FC<Props> = ({ ProgressProps, ...rest }) => {
  return (
    <Box
      position={'absolute'}
      top={0}
      right={0}
      bottom={0}
      left={0}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      {...rest}
    >
      <CircularProgress {...ProgressProps} />
    </Box>
  );
};
