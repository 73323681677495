const API = 'Activities';

export const API_ACTIVITIES = {
  GET_CLINICAL_MEETINGS: `${API}/GetClinicalMeetingsDynamic`,
  GET_SUPPORT_MEETINGS: `${API}/GetSupportMeetingsDynamic`,
  GET_SESSION_ITEMS: `${API}/GetSessionItemsDynamic`,
  PRESCRIPTION_RENEWAL: `${API}/PrescriptionRenewal`,
};

export interface SessionItem {
  id: string;
  rowIndex: number;
  isActive: boolean;
  userPatientProfileSessionID: string;
  fieldInputID: string;
  entryValueString: string;
  entryValueBool: boolean;
  entryValueNumber: number;
  entryValueDate: string;
  externalSystemValue: string;
  rank: number;
  userPatientProfileSession: UserPatientProfileSession;
  fieldInput: FieldInput;
}
export interface UserPatientProfile {
  id: string;
  rowIndex: number;
  isActive: boolean;
  appIdentityUserID: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userPhoto: string;
  startDate: string;
  cityID: string;
  genderID: string;
  hmoID: string;
  idNumber: string;
  mobilePhone: string;
  phone1: string;
  phone2: string;
  address: string;
  remarks: string;
  isTLC: boolean;
  dateOfBirth: string;
  fileID: string;
  generatePasswordEndDate: string;
  externalSystemID: string;
  height: number;
  weight: number;
  bmi: number;
  smoker: boolean;
}
export interface FieldInput {
  id: string;
  isActive: boolean;
  inputTypeID: string;
  labelKey: string;
  externalSystemTitle: string;
  highlight: boolean;
  inputType: InputType;
}
export interface InputType {
  id: string;
  isActive: boolean;
  title: string;
  inputTypeParameterID: string;
  inputTypeDataSourceID: string;
  isReadOnly: boolean;
  isWeight: boolean;
}
export interface UserPatientProfileSession {
  id: string;
  isActive: boolean;
  userPatientProfileID: string;
  notebookID: string;
  userEmployeeProfileID: string;
  entryDate: string;
  endDate: string;
  notebook: Notebook;
}
export interface Notebook {
  id: string;
  isActive: boolean;
  labelKey: string;
  externalSystemTitle: string;
  isClinical: boolean;
  isCommercial: boolean;
  migatedFromOldSystem: boolean;
}

export interface SupportMeeting {
  id: string;
  isActive: boolean;
  supportMeetingTypeID: string;
  userPatientProfileID: string;
  userEmployeeProfileID: string;
  userPatientProfileSessionID: string;
  meetingFromDateTime: string;
  meetingToDateTime: string;
  includeMeetingTime: boolean;
  remarks: string;
  userEmployeeProfile: UserEmployeeProfile;
  supportMeetingType: SupportMeetingType;
}
export interface SupportMeetingType {
  id: string;
  isActive: boolean;
  title: string;
  icon: string;
  color: string;
  nextMeetingNumberOfDays: number;
  rank: number;
  isTLC: boolean;
  supportMeetings: string[];
}

export interface ClinicalMeeting {
  id: string;
  isActive: true;
  clinicalMeetingTypeID: string;
  clinicalMeetingSubjectID: string;
  userPatientProfileID: string;
  userEmployeeProfileID: string;
  meetingFromDateTime: string;
  meetingToDateTime: string;
  arriveToClinicDateTime: string;
  visitDietitian: true;
  visitDoctor: true;
  clinicalMeetingSubject: ClinicalMeetingSubject;
  clinicalMeetingType: ClinicalMeetingType;
  userEmployeeProfile: UserEmployeeProfile;
}

export interface ClinicalMeetingType {
  id: string;
  rowIndex: number;
  isActive: boolean;
  title: string;
  icon: string;
  color: string;
  rank: number;
  clinicalMeetings: string[];
}
export interface ClinicalMeetingSubject {
  id: string;
  isActive: boolean;
  title: string;
  icon: string;
  color: string;
  rank: number;
  clinicalMeetings: string[];
}
export interface UserEmployeeProfile {
  id: string;
  isActive: boolean;
  appIdentityUserID: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userPhoto: string;
  startDate: string;
  userEmployeeProfileDepartmentID: string;
  userEmployeeProfilePermissionID: string;
  languageID: string;
  generatePasswordEndDate: string;
  mobilePhone: string;
  licenseNumber: string;
  signature: string;
  userCrmProfilePermission: UserCrmProfilePermission;
}

export interface UserCrmProfilePermission {
  title: string;
}

interface MeetingEmployee {
  fullName: string;
  position: string;
  userPhoto: string | null;
}

export interface DashboardClinicalMeeting {
  id: string;
  title: string;
  color: string;
  icon: string;
  clinicalMeetingTypeID: string;
  meetingFromDateTime: string;
  meetingToDateTime: string;
  employee: MeetingEmployee;
}
export interface DashboardSupportMeeting {
  id: string;
  meetingFromDateTime: string;
  meetingToDateTime: string;
  icon: string;
  title: string;
  color: string;
  supportMeetingTypeID: string;
  includeMeetingTime: boolean;
  employee: MeetingEmployee;
}

export interface DashboardSessionItem {
  id: string;
  entryDate: string;
  notebookLabelKey: string;
  activityType: string;
  weight: number;
}

export enum SESSION_FIELD_INPUT_TYPE_IDS {
  HOME = '4d17e7bd-5931-4e24-bf1d-ba4227d4060d',
}
