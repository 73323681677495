import { Badge, BadgeProps, Box, ButtonBase, ButtonBaseProps, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Classes {
  root: string;
  title: string;
  icon: string;
  inner: string;
  active: string;
}

interface Props extends ButtonBaseProps {
  className?: string;
  classes?: Partial<Classes>;
  icon: React.ReactNode;
  BadgeProps?: Partial<BadgeProps>;
  title: string;
  isActive?: boolean;
}

export const NavigationItem: React.FC<Props> = ({
  icon,
  title,
  children,
  className,
  classes,
  isActive,
  BadgeProps,
  ...rest
}) => {
  return (
    <ButtonBase
      className={clsx(
        style.root,
        classes?.root,
        className,
        isActive && style.active,
        isActive && classes?.active,
      )}
      {...rest}
      title={title}
    >
      <Badge color={'error'} {...BadgeProps}>
        <Box className={clsx(style.inner, classes?.inner)}>
          <div className={clsx(style.icon, classes?.icon)}>{icon}</div>
          <Typography className={clsx(style.title, classes?.title)} noWrap>
            {title}
          </Typography>
        </Box>
      </Badge>
    </ButtonBase>
  );
};
