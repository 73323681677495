import { DynamicParams, DynamicResult } from 'modules/dynamic-service';
import * as dynamic from 'utils/dynamic';
import { mergeFilters } from 'utils/dynamic';
import {
  apiAppProxy,
  apiRtk,
  transformResponseDynamic,
  transformResponseDynamicItemMaybe,
} from 'utils/service';
import {
  API_SUBSCRIPTION,
  CurrentSubscription,
  DashboardPatientSubscription,
  PatientSubscription,
  PreviousSubscription,
} from './models';

export * from './models';

class Service {
  getLatestSubscriptionDynamic = async <T = PatientSubscription, P extends DynamicParams = {}>(
    params?: P,
  ) => {
    const result = await apiAppProxy.get<DynamicResult<T>>(API_SUBSCRIPTION.GET_SUBSCRIPTIONS, {
      params: {
        ...params,
        filter: mergeFilters(params?.filter, 'isActive==true').join('&&'),
        orderBy: 'endDate desc',
        take: 1,
      },
    });
    const data = result.data.value[0];

    if (!data) {
      throw new Error('latest-subscription-not-found');
    }

    return { ...result, data };
  };
  getLatestSubscriptionIfExistDynamic = async <
    T = PatientSubscription,
    P extends DynamicParams = {},
  >(
    params?: P,
  ) => {
    const result = await apiAppProxy.get<DynamicResult<T>>(API_SUBSCRIPTION.GET_SUBSCRIPTIONS, {
      params: {
        ...params,
        filter: mergeFilters(params?.filter, 'isActive==true').join('&&'),
        orderBy: 'endDate desc',
        take: 1,
      },
    });
    const data = result.data.value[0];
    return { ...result, data: data ?? null };
  };
}

export const ServiceSubscriptions = new Service();

export const apiSubscriptions = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getDashboardSubscription: build.query<DashboardPatientSubscription, void>({
        query: () => ({
          url: API_SUBSCRIPTION.GET_SUBSCRIPTIONS,
          params: {
            select: dynamic.select<PatientSubscription>(
              'id',
              'price',
              'remark',
              'startDate',
              'endDate',
              'subscription.labelKey as subscriptionTitle',
              'subscription.listPrice as subscriptionListPrice',
              'subscription.durationMonths as subscriptionDurationMonths',
            ),
            orderBy: dynamic.orderBy<PatientSubscription>('endDate', 'desc'),
            take: 1,
          },
        }),
        transformResponse: transformResponseDynamicItemMaybe,
      }),
      getCurrentSubscriptions: build.query<CurrentSubscription[], void>({
        query: () => ({
          url: API_SUBSCRIPTION.GET_SUBSCRIPTIONS,
          params: {
            select: dynamic.select<PatientSubscription>(
              'id',
              'startDate',
              'endDate',
              'remark',
              'invoiceDocUrl',
              'price',
              'subscription.listPrice as listPrice',
              'subscription.labelKey as subscriptionTitle',
            ),
            orderBy: dynamic.orderBy<PatientSubscription>('startDate', 'desc'),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter<PatientSubscription>(
                  'endDate',
                  'DateTime.Now',
                  dynamic.moreOrEquals,
                ),
              )
              .join('&&'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getPreviousSubscriptions: build.query<PreviousSubscription[], void>({
        query: () => ({
          url: API_SUBSCRIPTION.GET_SUBSCRIPTIONS,
          params: {
            select: dynamic.select<PatientSubscription>(
              'id',
              'startDate',
              'endDate',
              'remark',
              'invoiceDocUrl',
              'price',
              'subscription.listPrice as listPrice',
              'subscription.labelKey as subscriptionTitle',
            ),
            orderBy: dynamic.orderBy<PatientSubscription>('startDate', 'desc'),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter<PatientSubscription>(
                  'endDate',
                  'DateTime.Now',
                  dynamic.lessOrEquals,
                ),
              )
              .join('&&'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
    };
  },
});
