import { DynamicService } from 'modules/dynamic-service';
import { apiApp } from 'utils/service';
import { API_WEB_PUSH, WebPushSubscribeInput } from './models';

export * from './models';

class Service extends DynamicService<Components.Schemas.UserPatientProfileWebPushNotification> {
  getPublicKey = async () => {
    const { data } = await apiApp.get<string>(API_WEB_PUSH.GET_KEYS);
    return data;
  };
  getPushSubscription = async () => {
    if (Notification?.permission !== 'granted') return;

    const reg = await navigator.serviceWorker.getRegistration();

    if (!reg) return;

    return await reg.pushManager.getSubscription();
  };
  subscribeUser = async (input: WebPushSubscribeInput) => {
    const { userPatientProfileID, subscription } = input;

    const subscriptionString = JSON.stringify(subscription);

    await this.post({
      userPatientProfileID,
      subscription: subscriptionString,
    });
  };
}

export const ServiceWebPush = new Service({
  mainField: 'id',
  getAll: API_WEB_PUSH.GET_ALL_DYNAMIC,
  post: API_WEB_PUSH.POST,
  patch: API_WEB_PUSH.PATCH,
  delete: API_WEB_PUSH.DELETE,
});
