import { APP_ROUTES } from 'configs';
import { useAppSelector } from 'hooks/redux';
import { useRedirectPath } from 'hooks/use-redirect-path';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { selectAccountUser } from 'store/accounts';

interface Props {
  redirectAfterLogin?: boolean;
}
export const RequireAuth: React.FC<Props> = ({ children, redirectAfterLogin = true }) => {
  const user = useAppSelector(selectAccountUser);

  const redirect = useRedirectPath();

  if (!user) {
    return (
      <Navigate
        to={APP_ROUTES.INDEX({ redirect: redirectAfterLogin ? redirect : undefined }).config}
        replace={true}
      />
    );
  }

  return <>{children}</>;
};
