import { useEffectNotifyError, usePageMeta } from 'hooks';
import React, { memo } from 'react';
import { apiPages } from 'services/pages';

interface Props {}

const Component: React.FC<Props> = ({ children }) => {
  const { data, error } = apiPages.endpoints.getPageLayout.useQuery();
  useEffectNotifyError(error);
  usePageMeta(data);
  return <>{children}</>;
};

export const AppPageLayout = memo(Component);
