import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Link, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { Logo } from 'components/logo';
import { APP_ROUTES } from 'configs';
import { useWebNotification } from 'contexts/web-notification/web-notification-provider';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PrivateNavigation } from 'layouts/private/components/navigation';
import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { apiPages } from 'services/pages';
import { actionAccountLogout, selectAccountStatuses } from 'store/accounts';
import { composeFunctions } from 'utils/other';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const PrivateHeader: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectAccountStatuses);
  const { data } = apiPages.useGetPageLayoutQuery();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const { requestUnsubscribe } = useWebNotification();
  const onLogout = useCallback(() => {
    dispatch(actionAccountLogout());
  }, [dispatch]);

  return (
    <div className={clsx(style.root, className)}>
      <div className={clsx('container', style.inner)}>
        <Link component={RouterLink} to={APP_ROUTES.HOME().config} underline={'none'}>
          <Logo>{data?.slogan}</Logo>
        </Link>
        {isDesktop && <PrivateNavigation isHeader />}
        <IconButton
          color={'secondary'}
          disabled={isLoading}
          onClick={composeFunctions(onLogout, requestUnsubscribe)}
        >
          <LogoutIcon />
        </IconButton>
      </div>
    </div>
  );
};
