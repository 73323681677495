import { createSelector } from '@reduxjs/toolkit';
import { createMap } from 'utils/other';
import { AppState } from '../index';

const selectState = (state: AppState) => state.labels;

export const selectLanguageID = createSelector(selectState, ({ languageID }) => {
  return languageID;
});
export const selectLabelsAll = createSelector(selectState, ({ labels }) => {
  return labels;
});
export const selectLabelsMap = createSelector(
  selectLanguageID,
  selectLabelsAll,
  (languageID, allLabels) => {
    const labelsList = (languageID ? allLabels[languageID] : []) || [];
    return createMap(labelsList, 'labelKey') || {};
  },
);

export const selectLabelsStatuses = createSelector(selectState, ({ isLoading, isInit, error }) => {
  return { isLoading, isInit, error };
});
