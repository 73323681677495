import { SerializedError } from '@reduxjs/toolkit';
import { useEffectNotifyError } from 'hooks/use-notify';
import { useMemo } from 'react';
import { apiActivities } from 'services/activities';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};
const useSourceNotifyError = <T extends { error: SerializedError | undefined }>(v: T) => {
  useEffectNotifyError(v.error);
  return v;
};

export const useSourceDashboardSessionItems = () =>
  useSourceNotifyError(useSourceData(apiActivities.useGetDashboardSessionItemsQuery()));
