import { apiRtk } from 'utils/service';
import { API_PAGES, Page } from './models';

export * from './models';

export const apiPages = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getPageLayout: builder.query<Page, void>({
      query: () => ({
        url: API_PAGES.GET_PAGE_LAYOUT,
      }),
    }),
  }),
});
