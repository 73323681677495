import { TaskTag } from 'services/todo-task-categories';
import { apiTodoTasks } from 'services/todo-tasks';
import { apiUploadedFiles } from 'services/uploaded-files';
import { put, takeEvery } from 'typed-redux-saga';

function* watchCreated() {
  yield* put(
    apiTodoTasks.endpoints.createAutoTodoTaskByTag.initiate(TaskTag.PatientAppAddingDocument),
  );
}
export const sagasUploadedFiles = [
  takeEvery(apiUploadedFiles.endpoints.postUploadedFiles.matchFulfilled, watchCreated),
];
