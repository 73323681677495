import clsx from 'clsx';
import { AppFooter } from 'components/footer';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './components';
import style from './index.module.scss';

export const PatientLayout = memo(() => {
  return (
    <div className={clsx(style.root)}>
      <Header />
      <main>
        <Outlet />
      </main>
      <AppFooter />
    </div>
  );
});
