import { PageLoader as Loading } from 'components/page-loader';
import { APP_ROUTES } from 'configs';
import { EmptyLayout, LoginLayout, PatientLayout, PrivateLayout } from 'layouts';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { PermissionProtector } from './permission-protector';

const HomePage = React.lazy(() => /* webpackChunkName: 'home' */ import('pages/home'));
const ProfilePage = React.lazy(() => /* webpackChunkName: 'profile' */ import('pages/profile'));
const DocumentsPage = React.lazy(
  () => /* webpackChunkName: 'documents' */ import('pages/documents'),
);
const HistoryMeetingsPage = React.lazy(
  () => /* webpackChunkName: 'documents' */ import('pages/history-meetings'),
);
const SignDocumentPage = React.lazy(
  () => /* webpackChunkName: 'sign-document' */ import('pages/sign-document'),
);
const PatientSignDocumentPage = React.lazy(
  () => /* webpackChunkName: 'patient-sign-document' */ import('pages/patient-sign-document'),
);
const PatientSignDocumentThanksPage = React.lazy(
  () =>
    /* webpackChunkName: 'patient-sign-document-thanks' */ import(
      'pages/patient-sign-document-thanks'
    ),
);

const PrescriptionsPage = React.lazy(
  () => /* webpackChunkName: 'prescriptions' */ import('pages/prescriptions'),
);
const DietDiaryPage = React.lazy(
  () => /* webpackChunkName: 'diet-diary' */ import('pages/diet-diary'),
);

const BackdoorPage = React.lazy(() => /* webpackChunkName: 'backdoor' */ import('pages/backdoor'));

const SurveyPage = React.lazy(() => /* webpackChunkName: 'survey' */ import('pages/survey'));
const PatientSurveyPage = React.lazy(
  () => /* webpackChunkName: 'patient-survey' */ import('pages/patient-survey'),
);
const PatientSurveyThanksPage = React.lazy(
  () => /* webpackChunkName: 'patient-survey-thanks' */ import('pages/patient-survey-thanks'),
);

const LoginPage = React.lazy(() => /* webpackChunkName: 'login' */ import('pages/login'));
const LoginCodePage = React.lazy(
  () => /* webpackChunkName: 'login-code' */ import('pages/login-code'),
);

const ToDoPage = React.lazy(() => /* webpackChunkName: 'not-found' */ import('pages/coming-soon'));

const NotFoundPage = React.lazy(
  () => /* webpackChunkName: 'not-found' */ import('pages/not-found'),
);

export const routes: RouteObject[] = [
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <PrivateLayout />,
    children: [
      {
        path: APP_ROUTES.HOME.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <HomePage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PRESCRIPTIONS.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <PrescriptionsPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DOCUMENTS.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <DocumentsPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DIET_DIARY.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <DietDiaryPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.HISTORY_MEETINGS.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <HistoryMeetingsPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <ProfilePage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SURVEY.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <SurveyPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <PatientLayout />,
    children: [
      {
        path: APP_ROUTES.PATIENT_HEALTH_SURVEY.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToAnswerSurvey'}>
                <PatientSurveyPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PATIENT_HEALTH_SURVEY_THANKS.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <PatientSurveyThanksPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: APP_ROUTES.INDEX.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <ToDoPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PATIENT_SIGN_DOCUMENT.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToSignDoc'}>
                <PatientSignDocumentPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PATIENT_SIGN_DOCUMENT_THANKS.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <PatientSignDocumentThanksPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.BACKDOOR.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <BackdoorPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SIGN_DOCUMENT.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <PermissionProtector permission={'isAllowToViewApp'}>
                <SignDocumentPage />
              </PermissionProtector>
            </RequireAuth>
          </React.Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <React.Suspense fallback={<Loading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
