export type PatchPartial<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export type ArrayType<T> = T extends (infer U)[] ? U : never;

export type DeepRequired<T> = {
  [K in keyof T]: Exclude<Required<DeepRequired<T[K]>>, undefined>;
};

export const isSettledRejected = (
  input: PromiseSettledResult<unknown>,
): input is PromiseRejectedResult => input.status === 'rejected';

export const isSettledFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';

export type ConditionPayload<T, C, Props, Payload> = T extends C ? Props : Props & Payload;
export type PromiseType<T> = T extends Promise<infer U> ? U : never;

export type FixServerObject<T, K extends keyof T> = T &
  Required<{ [P in K]: Exclude<T[P], null | undefined> }>;

export type ConditionPartial<T, C, Rest> = T extends C ? Partial<Rest> : Rest;

export type Paths<T> = keyof T | string;
