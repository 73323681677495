import { useAppSelector } from 'hooks';
import React, { memo } from 'react';
import { apiDocuments } from 'services/documents';
import { apiNotebooks } from 'services/notebooks';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-sessions';
import { selectAccountUser } from 'store/accounts';

export const AppPrivateDataLoader = memo(({ children }) => {
  const user = useAppSelector(selectAccountUser);

  // load documents
  apiDocuments.useGetFormDocumentsQuery(undefined, {
    skip: !user,
  });

  // load all surveys
  apiNotebooks.useGetAllSurveysQuery(undefined, {
    skip: !user,
  });
  apiUserPatientProfileSessions.useGetAllSurveyAnswersQuery(undefined, {
    skip: !user,
  });

  return <>{children}</>;
});
