import { createSelector } from '@reduxjs/toolkit';
import { convertToDate } from 'utils/dates';
import { AppState } from '../index';

const selectState = (state: AppState) => state.signDocument;

export const selectSignDocumentStatuses = createSelector(
  selectState,
  ({ isLoading, isInit, error, documentID, isDocumentProcessing }) => {
    return { isLoading, isInit, error, documentID, isDocumentProcessing };
  },
);

export const selectSignDocumentData = createSelector(selectState, ({ document }) => {
  return document;
});
export const selectSignDocumentFile = createSelector(selectState, ({ documentFile }) => {
  return documentFile;
});
export const selectSignDocumentFieldInputs = createSelector(selectState, ({ document }) => {
  return [...((document && document.formDocumentInputs) || [])].sort(
    (a, b) => Number(a.rank) - Number(b.rank),
  );
});
export const selectSignDocumentFocusedInput = createSelector(
  selectSignDocumentFieldInputs,
  (inputs) => {
    return inputs.find((item) => item.defaultValue === null || item.defaultValue === undefined);
  },
);
export const selectSignDocumentCanSign = createSelector(
  selectSignDocumentStatuses,
  selectSignDocumentFocusedInput,
  ({ isInit }, input) => {
    return isInit && input === undefined;
  },
);
export const selectSignDocumentTemplate = createSelector(selectState, ({ documentTemplate }) => {
  return documentTemplate;
});
export const selectSignDocumentPages = createSelector(selectState, ({ pages }) => {
  return pages;
});

export const selectSignDocumentIsSigned = createSelector(selectSignDocumentData, (document) => {
  return Boolean(document && document.signedDate);
});

export const selectSignDocumentIsExpired = createSelector(selectSignDocumentData, (document) => {
  if (!document) {
    return false;
  }
  const expiredDate = convertToDate(document.expiredDate);
  const nowDate = new Date();
  return expiredDate.getTime() < nowDate.getTime();
});
