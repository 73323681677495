import { ActionFromMatcher } from '@reduxjs/toolkit/src/tsHelpers';
import { apiAccounts } from 'services/accounts';
import { apiTodoTasks } from 'services/todo-tasks';
import { ServiceUsers } from 'services/users';
import {
  actionAccountGetUser,
  actionAccountLogout,
  actionAccountUpdateUser,
} from 'store/accounts/actions';
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';

function* sagaWatchLogout() {
  yield* put(apiRtk.util.resetApiState());
}
function* sagaWatchUpdateUser() {
  yield* put(actionAccountGetUser({ isBackground: true }));
}
function* watchCreateTask(
  action: ActionFromMatcher<typeof apiTodoTasks.endpoints.createTodoTask.matchFulfilled>,
) {
  try {
    yield* call(ServiceUsers.notifyEmployeeTask, String(action.payload.id));
  } catch (e) {
    console.error(e);
  }
}

export const sagasAccount = [
  takeLatest(apiAccounts.endpoints.renewalPrescription.matchFulfilled, sagaWatchUpdateUser),
  takeLatest(actionAccountLogout.fulfilled, sagaWatchLogout),
  takeEvery(apiTodoTasks.endpoints.createTodoTask.matchFulfilled, watchCreateTask),
  takeLatest(
    [
      actionAccountUpdateUser.fulfilled,
      actionAccountUpdateUser.rejected,
      apiAccounts.endpoints.updateCurrentUserInfo.matchFulfilled,
      apiAccounts.endpoints.updateCurrentUserInfoWithLog.matchFulfilled,
    ],
    sagaWatchUpdateUser,
  ),
];
