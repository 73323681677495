const API = 'Pages';

export const API_PAGES = {
  GET_PAGE_LAYOUT: `${API}/GetPageLayout`,
};

export interface Page {
  id: string;
  languageID: string;
  title: string;
  copyright: string;
  slogan: string;
  generalBodyScripts: string;
  generalFooterScripts: string;
  generalHeaderScripts: string;
  fullStoryCode: string;
  metaTitle: string;
  metaDescription: string;
  metaKeyWords: string;
}
