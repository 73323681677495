import * as dynamic from 'utils/dynamic';
import { apiAppProxy, DynamicResult } from 'utils/service';
import { API_LABELS, Label } from './models';

export * from './models';

class Service {
  async getDefaultLanguageID() {
    return apiAppProxy.get<string>(API_LABELS.GET_DEFAULT_LANGUAGE);
  }

  async getLabels(languageID: string) {
    const params = {
      filter: dynamic.makeFilter<Label>('languageID', languageID, dynamic.equals),
    };
    return apiAppProxy.get<DynamicResult<Label, typeof params>>(API_LABELS.GET_LABELS, { params });
  }
}

export const ServiceLabels = new Service();
