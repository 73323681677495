import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponseTransformer } from 'axios';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,7}$/gi.test(String(value));
};

export const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const _date = Date.parse(value);
        if (_date) {
          return `${value.split('.')[0]}.000Z`;
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const BASE_URL = process.env.REACT_APP_API || '';
export const apiAppProxy = axios.create({
  baseURL: `${BASE_URL}/api/proxy`,
  transformResponse: [axiosDateTransformer],
});
export const apiApp = axios.create({
  baseURL: `${BASE_URL}/api`,
  transformResponse: [axiosDateTransformer],
});

export interface BaseParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

export type DynamicResult<T extends any, P extends BaseParams = {}> = P extends {
  count: boolean;
}
  ? { value: T[]; count: number }
  : { value: T[] };

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError | Error> =>
  async ({ url, ...rest }) => {
    try {
      const result = await apiAppProxy(url || '', { method: 'get', ...rest });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };

export enum RTK_TAGS {
  UPLOADED_FILES = 'UPLOADED_FILES',
  PRIVATE_FILES = 'PRIVATE_FILES',
  SIGN_DOCUMENTS = 'SIGN_DOCUMENTS',

  SESSIONS = 'SESSIONS',
  DIET_DIARIES = 'DIET_DIARIES',

  ACCOUNT = 'ACCOUNT',
}
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    RTK_TAGS.UPLOADED_FILES,
    RTK_TAGS.PRIVATE_FILES,
    RTK_TAGS.SIGN_DOCUMENTS,
    RTK_TAGS.SESSIONS,
    RTK_TAGS.DIET_DIARIES,
    RTK_TAGS.ACCOUNT,
  ],
  endpoints: () => ({}),
});

export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export const transformResponseDynamic = <T extends { value: any[] }>(data: T) => {
  return data.value;
};
export const transformResponseDynamicItem = <T extends { value: any[] }>(data: T) => {
  const item = data.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item;
};
export const transformResponseDynamicItemMaybe = <T extends { value: any[] }>(data: T) => {
  return data.value[0];
};
export const prepareRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = String(key).endsWith('ID') && data[key] === '' ? null : data[key];
    return acc;
  }, data);
};

export const isMutationRejected = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isMutationFulfilled = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};
