import { createAsyncThunk } from '@reduxjs/toolkit';
import { Label, ServiceLabels } from 'services/labels';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { selectLabelsAll, selectLanguageID } from './selectors';

export const actionLabelsInit = createAsyncThunk<
  { data: Label[]; languageID: string },
  void,
  AppAsyncThunkConfig
>(`LABELS/init`, async (_, { getState }) => {
  try {
    let requestLanguageID = selectLanguageID(getState());

    if (!requestLanguageID) {
      const results = await ServiceLabels.getDefaultLanguageID();
      requestLanguageID = results.data;
    }

    const allLabels = selectLabelsAll(getState());
    let labelsList = allLabels[requestLanguageID];

    if (!labelsList) {
      const {
        data: { value: responseAllLabels },
      } = await ServiceLabels.getLabels(requestLanguageID);
      labelsList = responseAllLabels;
    }

    return { data: labelsList, languageID: requestLanguageID };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
