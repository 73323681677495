import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { Box, Button, Drawer, DrawerProps, Paper, Stack, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

interface Props extends DrawerProps {
  isOpen: boolean;
  onAccept: () => void;
  onClose: () => void;
}

export const PopupNotification: React.FC<Props> = ({ isOpen, onClose, onAccept, ...rest }) => {
  const { tp } = useTranslate();

  return (
    <Drawer anchor={'top'} open={isOpen} classes={{ paper: style.paper }} {...rest}>
      <Paper elevation={1} className={style.wrap}>
        <Stack direction={'row'} spacing={1.6} mb={2}>
          <Box>
            <NotificationsActiveOutlinedIcon
              color={'primary'}
              fontSize={'inherit'}
              sx={{ fontSize: '6rem' }}
            />
          </Box>
          <Box alignSelf={'center'} py={0.5}>
            <Typography variant={'h6'} color={'secondary'}>
              {tp('popup-notification-title')}
            </Typography>
            <Typography color={'secondary'} mt={0.3}>
              {tp('popup-notification-message')}
            </Typography>
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1.6}>
          <Button variant={'contained'} size={'small'} onClick={onAccept}>
            {tp('allow')}
          </Button>
          <Button variant={'outlined'} size={'small'} onClick={onClose}>
            {tp('no-thanks')}
          </Button>
        </Stack>
      </Paper>
    </Drawer>
  );
};
