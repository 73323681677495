import { ExtractParams } from 'modules/typed-routes';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const useRouteParams = <T>() => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const searchObject = useMemo(() => {
    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {});
  }, [searchParams]);

  return { ...params, ...searchObject } as ExtractParams<T>;
};
