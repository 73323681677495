import { useCallback, useEffect } from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { parseErrorData } from 'utils/service';
import { useTranslate } from './use-translate';

export const useNotify = () => {
  const { t } = useTranslate();

  const notifySuccess = toast.success;
  const notifyError = toast.error;

  const onError = useCallback(
    (e: { message?: string }) => {
      const err = parseErrorData(e);
      return notifyError(t(err.message));
    },
    [t, notifyError],
  );
  const onSuccess = useCallback(
    (content?: ToastContent, options?: ToastOptions | undefined) => {
      return notifySuccess(content || t('success'), options);
    },
    [t, notifySuccess],
  );
  return { onError, onSuccess, notifySuccess, notifyError };
};
export const useEffectNotifyError = (e: { message?: string } | undefined | null) => {
  const { onError } = useNotify();

  useEffect(() => {
    if (e) {
      onError(e);
    }
  }, [e, onError]);
};
