import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { schemaCurrentUserInfo } from 'services/accounts';
import { actionAccountLogout } from 'store/accounts';
import { actionSurveysSubmit } from 'store/surveys/actions';
import { InferType } from 'yup';

type InitState = {
  user: null | InferType<typeof schemaCurrentUserInfo>;
  items: { [surveyID: string]: SurveyItem };
};
type Answers = { [questionID: string]: any };

interface SurveyItem {
  surveyID: string;
  index: number;
  answers: Answers;
}

const initState = (): InitState => {
  return {
    user: null,
    items: {},
  };
};

const slice = createSlice({
  name: 'SURVEYS',
  initialState: initState(),
  reducers: {
    setIndex(state, action: PayloadAction<{ surveyID: string; index: number }>) {
      const { surveyID, index } = action.payload;
      const items = state.items;
      const item = items[surveyID] ?? { index: 0, answers: {} };
      items[surveyID] = { ...item, index };
    },
    setAnswers(state, action: PayloadAction<{ surveyID: string; answers: Answers }>) {
      const { surveyID, answers } = action.payload;
      const items = state.items;
      const item = items[surveyID] ?? { index: 0, answers: {} };
      items[surveyID] = { ...item, answers: answers };
    },
    setUserInfo(state, action: PayloadAction<InferType<typeof schemaCurrentUserInfo>>) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionSurveysSubmit.fulfilled, (state, action) => {
      const surveyID = action.meta.arg.survey.id;
      const items = state.items;
      if (surveyID) {
        delete items[surveyID];
      }
    });
    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initState();
    });
  },
});
export const actionsSurvey = slice.actions;
export const reducerSurveys = slice.reducer;
