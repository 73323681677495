import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { AppThemeOptions } from 'styles/theme';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

export const themeLtr = createTheme(AppThemeOptions);
export const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
  prepend: true,
});

export const themeRtl = createTheme({ ...AppThemeOptions, direction: 'rtl' });
export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

const Component = ({ children }: React.PropsWithChildren<any>) => {
  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
  }, []);

  const theme = themeRtl;
  const cache = cacheRtl;

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export const AppThemeProvider = memo(Component);
