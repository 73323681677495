import { Theme, Typography, useMediaQuery } from '@mui/material';
import { AppFooter } from 'components/footer';
import logoTextSrc from 'images/logo-text.svg';
import logoSrc from 'images/logo.svg';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { apiPages } from 'services/pages';
import style from './index.module.scss';

export const LoginLayout = memo(() => {
  const { data } = apiPages.endpoints.getPageLayout.useQuery();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <div className={style.root}>
      {isDesktop && <div className={style.bg} />}
      <div className={style.content}>
        <div className={style.header}>
          <img className={style.headerLogo} src={logoSrc} alt={''} />
          <img className={style.headerText} src={logoTextSrc} alt={''} />
          <Typography
            className={style.title}
            variant={'h6'}
            color={'secondary'}
            component={'h1'}
            textAlign={'center'}
          >
            {data?.slogan}
          </Typography>
        </div>
        <div className={style.form}>
          <Outlet />
        </div>
      </div>
      <AppFooter />
    </div>
  );
});
