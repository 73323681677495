import { ServiceMediaPrivate } from 'services/media-uploads';
import { DateValue } from 'utils/dates';
import * as dynamic from 'utils/dynamic';
import { base64ToFileStream, getFileInfo } from 'utils/file-uploader';
import {
  apiAppProxy,
  apiRtk,
  DynamicResult,
  parseErrorData,
  RTK_TAGS,
  transformResponseDynamic,
} from 'utils/service';
import { API_UPLOADED_FILES, UploadedFile, UploadedFileInput } from './models';

export * from './models';

class Service {
  async post(data: UploadedFileInput) {
    const { fileURL, userPatientProfileID, ...rest } = data;
    const { fileName, value } = getFileInfo(fileURL);
    const {
      data: { filePath },
    } = await ServiceMediaPrivate.uploadFile({
      fileName: `${userPatientProfileID}__${fileName}`,
      fileStreamString: base64ToFileStream(value),
    });

    return apiAppProxy.post<UploadedFile>(API_UPLOADED_FILES.POST, { ...rest, fileURL: filePath });
  }
  async delete(data: Pick<UploadedFile, 'id'>) {
    return apiAppProxy.delete<UploadedFile>(API_UPLOADED_FILES.DELETE(data), {
      params: { id: data.id },
    });
  }
}

const ServiceUploadedFiles = new Service();

interface GetUploadedFilesModel
  extends Pick<UploadedFile, 'id' | 'fileURL' | 'title' | 'uploadedByPatient'> {
  createdDate: DateValue;
}

export const apiUploadedFiles = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getUploadedFiles: build.query<GetUploadedFilesModel[], void>({
        query: () => ({
          url: API_UPLOADED_FILES.GET,
          params: {
            select: dynamic.select<UploadedFile>(
              'id',
              'fileURL',
              'title',
              'uploadedByPatient',
              'createdDate',
            ),
            filter: dynamic
              .mergeFilters(dynamic.makeFilter<UploadedFile>('isActive', true, dynamic.equals))
              .join('&&'),
            orderBy: dynamic.orderBy('createdDate', 'desc'),
          },
        }),
        transformResponse: async (data: DynamicResult<GetUploadedFilesModel>) => {
          return transformResponseDynamic(data);
        },
        providesTags: [{ type: RTK_TAGS.UPLOADED_FILES }],
      }),
      postUploadedFiles: build.mutation<UploadedFile, UploadedFileInput>({
        queryFn: async (data) => {
          try {
            const response = await ServiceUploadedFiles.post(data);
            return { data: response.data };
          } catch (e: any) {
            return { data: undefined, error: parseErrorData(e) };
          }
        },
        invalidatesTags: [{ type: RTK_TAGS.UPLOADED_FILES }],
      }),
      deleteUploadedFiles: build.mutation<UploadedFile, Pick<UploadedFile, 'id'>>({
        queryFn: async (data) => {
          try {
            const response = await ServiceUploadedFiles.delete(data);
            return { data: response.data };
          } catch (e: any) {
            return { data: undefined, error: parseErrorData(e) };
          }
        },
        invalidatesTags: [{ type: RTK_TAGS.UPLOADED_FILES }],
      }),
    };
  },
});
