import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { apiPages } from 'services/pages';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const AppFooter: React.FC<Props> = ({ className }) => {
  const { data } = apiPages.endpoints.getPageLayout.useQuery();
  return (
    <footer className={clsx(style.root, className)}>
      <Typography variant={'body2'}>{data?.copyright}</Typography>
    </footer>
  );
};
