const API = 'UserPatientProfileSessionItems';

export const API_USER_PATIENT_PROFILE_SESSION_ITEMS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserPatientProfileSessionItem, 'id'>) =>
    `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserPatientProfileSessionItem, 'id'>) =>
    `${API}/Delete/${data.id}`,
};

export interface GetAnswersBySessionIDItem
  extends Pick<Components.Schemas.UserPatientProfileSessionItem, 'id' | 'entryValueString'> {
  fieldInput: Pick<
    Required<Components.Schemas.UserPatientProfileSessionItem>['fieldInput'],
    'labelKey'
  >;
}
