import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetDocumentModel, ServiceDocuments } from 'services/documents';
import { actionAccountLogout } from 'store/accounts';
import { actionSignDocumentInit } from './actions';

interface InitState {
  isInit: boolean;
  isLoading: boolean;
  isDocumentProcessing: boolean;
  error: null | Error;
  documentID: string | null;
  document: SignDocument | null;
  documentTemplate: Blob | null;

  documentFile: Uint8Array | null;
  pages: SignDocumentPage[];
}

export interface SignDocument extends GetDocumentModel {}
export interface SignDocumentPage {
  index: number;
  inputs: Components.Schemas.FormDocumentInput[];
}

const initState = (): InitState => {
  return {
    isInit: false,
    isLoading: false,
    isDocumentProcessing: false,
    error: null,
    documentID: null,
    document: null,
    documentTemplate: null,
    documentFile: null,
    pages: [],
  };
};

const slice = createSlice({
  name: 'SIGN_DOCUMENT',
  initialState: initState(),
  reducers: {
    actionSignDocumentSetPages(state, action: PayloadAction<SignDocumentPage[]>) {
      state.pages = action.payload;
    },
    actionSignDocumentSetDocumentFile(state, action: PayloadAction<Uint8Array>) {
      state.documentFile = action.payload;
    },
    actionSignDocumentUpdateField(
      state,
      action: PayloadAction<Pick<Components.Schemas.FormDocumentInput, 'id' | 'defaultValue'>>,
    ) {
      if (state.document) {
        state.document.formDocumentInputs = state.document.formDocumentInputs.map((fieldInput) => {
          if (fieldInput.id !== action.payload.id) return fieldInput;
          return {
            ...fieldInput,
            defaultValue: ServiceDocuments.setFieldInputDefaultValue({
              fieldInput,
              value: action.payload.defaultValue,
            }),
          };
        });
      }
    },
    actionSignDocumentSetProcessing(state, action: PayloadAction<boolean>) {
      state.isDocumentProcessing = action.payload;
    },
    actionSignDocumentReset() {
      return initState();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionSignDocumentInit.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.documentID = action.meta.arg.documentID;
      })
      .addCase(actionSignDocumentInit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.isInit = true;
        state.document = action.payload.document;
        state.documentTemplate = action.payload.documentTemplate;
      })
      .addCase(actionSignDocumentInit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.isInit = true;
      });

    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initState();
    });
  },
});

const actions = slice.actions;

export const {
  actionSignDocumentSetPages,
  actionSignDocumentSetDocumentFile,
  actionSignDocumentUpdateField,
  actionSignDocumentSetProcessing,
  actionSignDocumentReset,
} = actions;
export const reducerSignDocument = slice.reducer;
