import { apiAppProxy } from 'utils/service';
import { API_ERROR_HELPERS, SendErrorInput } from './models';

export * from './models';

class Service {
  async sendError(input: SendErrorInput) {
    return apiAppProxy.post(API_ERROR_HELPERS.SEND_ERROR, {
      userPatientProfileID: input.userPatientProfileID,
      error: JSON.stringify({
        message: input.message,
        location: window.location.href,
        stack: input.stack,
        userAgent: navigator.userAgent,
      }),
    });
  }
}

export const ServiceErrorHelpers = new Service();
