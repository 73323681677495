import { useAppSelector } from 'hooks/redux';
import { useRedirectPath } from 'hooks/use-redirect-path';
import React from 'react';
import { UserPermissions } from 'services/accounts';
import { selectAccountUserPermissions } from 'store/accounts';
import { ScreenNotEnoughPermissions } from '../components/screen-not-enough-permissions';

interface Props {
  permission: keyof UserPermissions;
}
export const PermissionProtector: React.FC<Props> = ({ children, permission }) => {
  const permissions = useAppSelector(selectAccountUserPermissions);

  const redirect = useRedirectPath();

  if (!permissions) {
    return <ScreenNotEnoughPermissions redirect={redirect} />;
  }

  if (!permissions[permission]) {
    return <ScreenNotEnoughPermissions redirect={redirect} />;
  }

  return <>{children}</>;
};
