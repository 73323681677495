import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrentAppUser, ServiceAccounts } from 'services/accounts';
import { updateAuthTokens } from 'utils/cookies';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { actionAccountSetPermissions_TODO } from './slice';

interface ActionAccountGetUserArg {
  isBackground: boolean;
}
export const actionAccountGetUser = createAsyncThunk<
  CurrentAppUser,
  ActionAccountGetUserArg,
  AppAsyncThunkConfig
>(`ACCOUNT/get`, async (_) => {
  try {
    const { data } = await ServiceAccounts.getCurrentUser();
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountGeneratePassword = createAsyncThunk<
  void,
  { mobilePhone: string },
  AppAsyncThunkConfig
>(`ACCOUNT/generate`, async (data) => {
  try {
    await ServiceAccounts.generatePassword(data);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountSedCode = createAsyncThunk<
  void,
  { mobilePhone: string; code: string },
  AppAsyncThunkConfig
>(`ACCOUNT/sendCode`, async (data, { dispatch }) => {
  try {
    const {
      data: {
        expires,
        jwt: { token, refreshToken },
      },
    } = await ServiceAccounts.sendCode(data);
    updateAuthTokens({ token, refreshToken, expires });

    await dispatch(actionAccountGetUser({ isBackground: false })).unwrap();

    dispatch(
      actionAccountSetPermissions_TODO({
        isAllowToAnswerSurvey: true,
        isAllowToSignDoc: true,
        isAllowToViewApp: true,
      }),
    );
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountLogout = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `ACCOUNT/logout`,
  async () => {
    try {
      await ServiceAccounts.logout();
      updateAuthTokens();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionAccountUpdateUser = createAsyncThunk<
  void,
  Partial<Components.Schemas.CurrentUserProfileResponse>,
  AppAsyncThunkConfig
>(`ACCOUNT/update`, async (data) => {
  try {
    await ServiceAccounts.patchCurrentUser(data);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
