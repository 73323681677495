import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.surveys;

export const selectStateSurveys = createSelector(selectState, (state) => {
  return state.items;
});
export const selectStateSurveyUser = createSelector(selectState, (state) => {
  return state.user;
});
